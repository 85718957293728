import { createSlice } from '@reduxjs/toolkit';

export const BannerSlice = createSlice({
  name: 'Banner',
  initialState: {
    variant: 'info',
    message: '',
    open: false,
    curComponent: '',
  },
  reducers: {
    showBanner(state, { payload }) {
      const { variant, message } = payload;
      state.variant = variant;
      state.message = message;
      state.open = true;
      state.curComponent = payload?.curComponent ? payload.curComponent : '';
    },
    closeBanner(state) {
      state.open = false;
      state.variant = 'info';
      state.message = '';
      state.curComponent = '';
    },
  },
});

export const { showBanner, closeBanner } = BannerSlice.actions;

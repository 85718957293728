import Typography from 'apollo-react/components/Typography';
import React, { Component } from 'react';
interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Typography
            variant='title2'
            gutterBottom
            data-testid='errorboundary'
            style={{ textAlign: 'center', padding: '5rem', fontSize: '32px' }}
          >
            Something went Wrong
          </Typography>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;

import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { POWER_BI } from '../../../Constants';

const AddDashboard = (props) => {
  const { rowData, tenantBiClientsData } = props;
  const [data, setData] = useState({});
  const [displayPowerBiData, setDisplayPowerBiData] = useState(rowData.biClientName === POWER_BI ? true : false);


  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          <Select
            label='BI Client'
            helperText='You can select one option'
            canDeselect={false}
            value={JSON.stringify({ id: rowData?.tenantBiClientId, biClientName: rowData?.biClientName })}
            onChange={(e) => {
              rowData.tenantBiClientId = JSON.parse(e.target.value).id;
              rowData.biClientName = JSON.parse(e.target.value).biClientName
              if (rowData.biClientName === POWER_BI) {
                setDisplayPowerBiData(true)
              } else {
                setDisplayPowerBiData(false)
              }
              setData({
                ...data, tenantBiClientId: toNumber(JSON.parse(e.target.value).id)
              });
            }}
            placeholder='Select item...'
            required
            style={{ minWidth: 500 }}>
            {tenantBiClientsData.map((tenantBiClient) => {
              return <MenuItem key={tenantBiClient.id} value={JSON.stringify(tenantBiClient)}>{tenantBiClient.biClientName}</MenuItem>
            })}
          </Select>
        </Grid>
        <Grid item md={6}>
          <TextField
            label='Name'
            placeholder='Dashboard Name'
            style={{ minWidth: 225 }}
            defaultValue={rowData?.name ? rowData.name : ''}
            onChange={(e) => {
              rowData.name = e.target.value;
              setData({
                ...data, name: e.target.value
              });
            }}
            required
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label='Description'
            placeholder='Description'
            style={{ minWidth: 225 }}
            defaultValue={rowData?.description ? rowData.description : ''}
            onChange={(e) => {
              rowData.description = e.target.value;
              setData({
                ...data, description: e.target.value
              });
            }}
          />
        </Grid>
        {displayPowerBiData && (
          <>
            <Grid item md={6}>
              <TextField
                label='Entity ID'
                placeholder='Entity ID'
                style={{ minWidth: 225 }}
                defaultValue={rowData?.config.entityId ? rowData.config.entityId : ''}
                onChange={(e) => {
                  rowData.config.entityId = e.target.value;
                  setData({
                    ...data, config: {
                      entityId: e.target.value,
                      entityName: rowData.config.entityName,
                      entityType: rowData.config.entityType,
                      paginatedReportIds: rowData.config.paginatedReportIds
                    }
                  });
                }}
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label='Entity Name'
                placeholder='Entity Name'
                style={{ minWidth: 225 }}
                defaultValue={rowData?.config.entityName ? rowData.config.entityName : ''}
                onChange={(e) => {
                  rowData.config.entityName = e.target.value;
                  setData({
                    ...data, config: {
                      entityId: rowData.config.entityId,
                      entityName: e.target.value,
                      entityType: rowData.config.entityType,
                      paginatedReportIds: rowData.config.paginatedReportIds
                    }
                  });
                }}
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label='Paginated Report IDs'
                placeholder='Paginated Report IDs'
                style={{ minWidth: 225 }}
                defaultValue={rowData?.config.paginatedReportIds ? rowData.config.paginatedReportIds : ''}
                onChange={(e) => {
                  const inputPaginatedIds: Array<string> = [];
                  if (e.target.value) {
                    inputPaginatedIds.push(e.target.value);
                  }
                  rowData.config.paginatedReportIds = inputPaginatedIds;
                  setData({
                    ...data, config: {
                      entityId: rowData.config.entityId,
                      entityName: rowData.config.entityName,
                      entityType: rowData.config.entityType,
                      paginatedReportIds: inputPaginatedIds
                    }
                  });
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default AddDashboard;

import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialConfig } from '../../Redux/Slice/InitialConfigSlice';
import { getCaApiHeaders } from '../../Service/ApiHeaderService';
import DashboardManagement from './DashboardManagement/DashboardManagement';
import Generic from './Generic/Generic';
import MenuManagement from './MenuManagement/MenuManagement';
import './style.css';

export const AdminPage = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initialConfig())
  }, [dispatch])

  axios.interceptors.request.use(
    async (request: any) => {
      request.headers = { ...request.headers, ...getCaApiHeaders() };
      return request;
    },
    (error) => {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  const handleChangeTab = (event, val) => {
    setValue(val);
  };
  return (
    <React.Fragment>
      <div>
        <div className='page-header'>
          <div className='page-title'>CA Admin</div>
          <Tabs value={value} onChange={handleChangeTab} truncate style={{ padding: '0 30px' }}>
            <Tab label='Dashboard Management' />
            <Tab label='Menu Management' />
            <Tab label='Tenant & App Settings' />
          </Tabs>
        </div>
      </div>
      <div style={{ backgroundColor: '#f6f7fb', padding: '30px' }}>
        {value === 0 && <DashboardManagement />}
        {value === 1 && <MenuManagement />}
        {value === 2 && <Generic />}
      </div>
    </React.Fragment>
  );
};

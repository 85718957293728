import { createSlice } from '@reduxjs/toolkit';

export const MainMenuPermissionsSlice = createSlice({
    name: 'mainMenuPermissionsSlice',
    initialState: {
        mainMenuPermissions: {}
    },
    reducers: {
        setMainMenuPermissions(state, { payload }) {
            state.mainMenuPermissions = payload;
        }
    }
});

export const mainMenuPermissionsReducer = MainMenuPermissionsSlice.reducer;
import { Box, Grid } from '@mui/material';
import React from 'react';

type Props = {
  children?: JSX.Element;
};

export const EmbedLayout = (props: Props) => {
  return (
    <React.Fragment>
      <Grid>
        <Box>
          <div style={{ margin: '0 auto', width: '100%' }}>
            {props.children}
          </div>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

import { createSlice } from '@reduxjs/toolkit';

export const AppSwitcherSlice = createSlice({
  name: 'AppSwitcher',
  initialState: {
    htmlContent: '',
  },
  reducers: {
    updateHtmlContent(state, { payload }) {
      state.htmlContent = payload;
    },
  },
});

export const { updateHtmlContent } = AppSwitcherSlice.actions;

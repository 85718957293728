import { makeStyles } from '@mui/styles';
import { Modal, Typography } from 'apollo-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { gotoCore } from '../../Utils';

const Logout = () => {
  const location = useLocation();
  const onLogin = async () => {
    gotoCore();
  };
  useEffect(() => {
    if (!location?.state?.logout) {
      gotoCore();
    }
  }, [location.state]);

  window.history.replaceState({}, document.title);

  const styles = {
    modal: {
      width: '980px',
    },
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <>
      <img
        src='images/logoutBackground.webp'
        alt='...'
      />
      <Modal
        variant='default'
        title='Logged out'
        open={true}
        className={classes.modal}
        buttonProps={[
          { label: 'Log in', onClick: onLogin, variant: 'primary' },
        ]}
      >
        <Typography variant='h5'>
          Thank you for using the Clinical Data Analytics Suite. You are now
          logged out.
        </Typography>
      </Modal>
    </>
  );
};

export default Logout;

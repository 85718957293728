import axios from 'axios';
import { getUserId } from '../components/Header/utils';
import { Routes, USER_ACTIONS } from '../Constants/PathConstants';
import { getApiPath } from '../Utils/PathUtils';
const logUserAction = async (eventCategory: string, eventSubCategory: string, action: string, details: any) => {
  const logData = {
    userId: getUserId(),
    eventCategory: eventCategory,
    eventSubCategory: eventSubCategory,
    adtAction: action,
    details: details
  };
  const URL = getApiPath(USER_ACTIONS, Routes.AUDITS)
  await axios.post(URL, logData)
    .catch(error => {
      console.log("Error while logging user action", error)
    });
};

export default logUserAction;

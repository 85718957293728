import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { DashboardCard } from './DashboardCard';

export const DashboardGrid = (props) => {
  const items = props.child.children.map((item: any) => <DashboardCard item={item} key={JSON.stringify(item)} />);

  return (
    <div>
      <Typography variant={'h4'}>
        {props.child.name}
      </Typography>
      <Typography>
        {props.child.description}
      </Typography>
      <Grid container>
        {items}
      </Grid>
    </div>
  )
};
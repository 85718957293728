import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AppSwitcherSlice } from './Slice/AppSwitcherSlice';
import { BannerSlice } from './Slice/BannerSlice';
import { embedTokenReducer } from './Slice/EmbedTokenSlice';
import { GenAiSlice } from './Slice/GenAiSlice';
import { InProgressMessageSlice } from './Slice/InProgressMessageSlice';
import { InitialConfigSlice } from './Slice/InitialConfigSlice';
import { mainMenuPermissionsReducer } from './Slice/MainMenuPermissionsSlice';
import { ReportSlice } from './Slice/ReportSlice';
import { selectedReportReducer } from './Slice/SelectedReportSlice';
import { selectedStudiesReducer } from './Slice/SelectedStudiesSlice';
import { selfServiceReportReducer } from './Slice/SelfServiceReportSlice';
import { AiApi } from './apis/AiApi';
import { ConfigApi } from './apis/ConfigApi';
import { UserStudiesApi } from './apis/UserStudiesApi';

const reducerObject = combineReducers({
  AppSwitcher: AppSwitcherSlice.reducer,
  Banner: BannerSlice.reducer,
  EmbededReport: ReportSlice.reducer,
  InProgressMsg: InProgressMessageSlice.reducer,
  InitialConfig: InitialConfigSlice.reducer,
  selectedStudiesSlice: selectedStudiesReducer,
  selectedReportSlice: selectedReportReducer,
  embedTokenSlice: embedTokenReducer,
  mainMenuPermissionsSlice: mainMenuPermissionsReducer,
  genAi: GenAiSlice.reducer,
  selfServiceReportSlice: selfServiceReportReducer,

  [ConfigApi.reducerPath]: ConfigApi.reducer,
  [UserStudiesApi.reducerPath]: UserStudiesApi.reducer,
  [AiApi.reducerPath]: AiApi.reducer,
});

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({
  serializableCheck: false
})
  .concat(UserStudiesApi.middleware, ConfigApi.middleware, AiApi.middleware)

export const store = configureStore({
  reducer: reducerObject,
  middleware: middleware
});

// TODO - Need to refactor the exports
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
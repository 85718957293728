import axios from 'axios';
import { GENERAL_ERROR_MSG } from '../Constants';

export const createWorkSpace = async (url) => {
  return axios
    .post(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {
        message:
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : GENERAL_ERROR_MSG,
        error: true,
      };
    });
}

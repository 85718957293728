import Close from 'apollo-react-icons/Close';
import Card from 'apollo-react/components/Card';
import CircularProgress from 'apollo-react/components/CircularProgress';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { useDispatch } from 'react-redux';
import { closeInProgressMsg } from '../../Redux/Slice/InProgressMessageSlice';

export const InprogressMessage = (props: {
  type: string;
  reportName: string;
  isEmbedPage: boolean;
}) => {
  const { type, reportName, isEmbedPage } = props;
  const dispatch = useDispatch();

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        top: isEmbedPage ? '5px' : '100px',
        marginRight: '10rem',
      }}>
      <Card style={{ maxWidth: 350, height: 85 }}>
        <Grid container style={{ padding: '0.5rem' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Close
              data-testid='closeIcon'
              fontSize='extraSmall'
              style={{ fontSize: '14px', cursor: 'pointer' }}
              onClick={() => dispatch(closeInProgressMsg())}
            />
          </Grid>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '0.5rem' }}>
              <CircularProgress variant='indeterminate' size={20} />
            </div>
            <div>
              <Typography variant='title2' style={{ fontSize: '14px' }}>
                {`Export to ${type} in progress`}
              </Typography>
              <Typography variant='caption' style={{ fontSize: '12px', lineHeight: 0.5 }}>
                {`your report ${reportName} is being exported to ${type} File.This might take a few minutes`}
              </Typography>
            </div>
          </div>
        </Grid>
      </Card>
    </div>
  );
};

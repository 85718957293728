import Divider from 'apollo-react/components/Divider';
import Typography from 'apollo-react/components/Typography';
import { useAppStyles } from "../../AppStyles";
import { HOME_HEADER_TITLE } from '../../Constants';
import { StudiesAutoCompleteDropdown } from "../../components/studies/StudiesAutoCompleteDropdown";

export const HomeHeader = () => {
    const appClasses = useAppStyles();

    return (
        <div className={appClasses.divContainer}>
            <Typography variant="h1">
                {HOME_HEADER_TITLE}
            </Typography>
            <StudiesAutoCompleteDropdown width={'45%'} />
            <Divider style={{ borderBottomWidth: '2px', borderColor: 'black' }} />
        </div>
    );
}
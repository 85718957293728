import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { HOME_PATH } from "../../Constants";
import routes from "../../RootRoutes";
import { isAuthorised } from "../../Service/AuthService";
import { gotoCore } from "../../Utils";

const AppRouter = () => {
    return (
        <Router>
            <Switch>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        render={() => isAuthorised(route.authentication) ? (
                            <route.layout>
                                <route.component />
                            </route.layout>
                        ) : (
                            gotoCore()
                        )} />
                ))}
                <Route
                    path='*'
                    render={() => isAuthorised() ? <Redirect to={HOME_PATH} /> : gotoCore()} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
import axios from 'axios';
import { GENERAL_ERROR_MSG } from '../Constants';
import { GET_CONFIG, GET_UI_CONFIG, Routes } from '../Constants/PathConstants';
import { uuidv4 } from '../Utils';
import { getApiPath } from '../Utils/PathUtils';

export const getMenuData = (data: any) => {
  const tenantName = data?.tenantName;
  const tenantId = data?.tenantId;
  const menu = data?.menus?.map((item: any) => {
    if (item.children) {
      return {
        children: item.children?.map((el: any) => {
          if (el.children) {
            return {
              name: el.name,
              description: el.description,
              children: el.children.map((item) => {
                return {
                  pbiEntityName: item.biClient?.entityName,
                  pbiEntityType: item.biClient?.entityType,
                  permissions: item.permissions,
                  reportId: item.biClient?.entityId,
                  description: item.description,
                  name: item?.name,
                  clientName: item.biClient?.name,
                  workspaceId: item.biClient?.workspaceId,
                  isSingleStudyReport: item.biClient?.isSingleStudyReport,
                  thumbnailImageLink: item.biClient?.thumbnailImageLink,
                };
              }),
              reportId: uuidv4(),
            }
          } else {
            return {
              pbiEntityName: el.biClient?.entityName,
              pbiEntityType: el.biClient?.entityType,
              permissions: el?.permissions,
              reportId: el.biClient?.entityId,
              name: el?.name,
              clientName: el.biClient?.name,
              workspaceId: el.biClient?.workspaceId,
              isSingleStudyReport: el.biClient?.isSingleStudyReport,
              thumbnailImageLink: el.biClient?.thumbnailImageLink,
            };
          }
        }),
        name: item.name,
        description: item.description,
        reportId: uuidv4(), //Added for parent menu Identification purpose
      };
    } else {
      return {
        pbiEntityName: item.biClient?.entityName,
        pbiEntityType: item.biClient?.entityType,
        permissions: item?.permissions,
        reportId: item.biClient?.entityId,
        name: item.name,
        description: item.description,
        clientName: item.biClient?.name,
        workspaceId: item.biClient?.workspaceId,
        isSingleStudyReport: item.biClient?.isSingleStudyReport,
        thumbnailImageLink: item.biClient?.thumbnailImageLink,
      };
    }
  });
  return { tenantName, tenantId, menu };
};

export const getUserConfig = async () => {
  const URL = getApiPath(GET_UI_CONFIG, Routes.PBI)
  const data = await axios
    .get(URL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {
        error: true,
        message:
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : GENERAL_ERROR_MSG,
      };
    });

  if (!data.error) {
    const menuItems = getMenuData(data?.menuItems);
    const appSwitcher = data?.appSwitcher;
    const targetWorkspace = data?.targetWorkspace;
    const embedToken = data?.embedToken
    return { menuItems, appSwitcher, targetWorkspace, embedToken};
  } else {
    return data;
  }
};

export const getInitialConfig = async () => {
  const URL = getApiPath(GET_CONFIG, Routes.PBI)
  return axios
    .get(URL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {
        error: true,
        message:
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : GENERAL_ERROR_MSG,
      };
    });
}
import { makeStyles } from '@mui/styles';
import ArrowDown from 'apollo-react-icons/ArrowDown';
import Check from 'apollo-react-icons/Check';
import Button from 'apollo-react/components/Button';
import Menu from 'apollo-react/components/Menu';
import MenuItem from 'apollo-react/components/MenuItem';
import Typography from 'apollo-react/components/Typography';
import React, { useContext } from "react";
import { DISABLE_STUDIES_LENGTH_ARR } from '../../Constants';
import { HomeContext } from "../../Pages/Home/HomeLayout";

const useStyles = makeStyles({
  analyticMenuContainer: {
    width: '230px',
    display: 'flex',
    margin: '10px',
    gap: '5px'
  },
  panelContent: {
    overflow: 'auto',
    height: '450px',
    width: '346px',
  }
});

export const AnalyticMenu = (props) => {

  const { selectedReport, report, setReport, studiesLength } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const HomeContextData: any = useContext(HomeContext);
  const { configData } = HomeContextData;

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleRequestClose = () => {
    setOpen(false);
  }

  const handleMenuClick = (event, menu) => {
    setReport({
      name: menu.name,
      parentMenuName: menu.parentMenuName,
      reportId: menu.reportId,
      workspaceId: menu.workspaceId,
      permissions: menu.permissions,
      clientName: menu.clientName,
      isSingleStudyReport: menu.isSingleStudyReport,
    });
    setOpen(false);
  };

  const MenuItemComponent = ({ menuItem }) => {
    return (
      <div>
        <Typography variant='h1' style={{ fontWeight: 'bold', margin: '15px', fontSize: '16px' }}>
          {menuItem.name}
        </Typography>
        {menuItem.children.map((item) => (
          <AnalyticMenuItem menuItem={{ ...item, parentMenuName: menuItem.name }} />
        ))}
      </div>
    );
  };

  const AnalyticMenuItem = ({ menuItem }) => {
    const selected = selectedReport.reportId === menuItem.reportId;
    return (
      <MenuItem
        disabled={!DISABLE_STUDIES_LENGTH_ARR.includes(studiesLength) && menuItem.isSingleStudyReport}
        onClick={(event) => handleMenuClick(event, menuItem)}
        style={selected ? { fontWeight: 500, fontSize: '16px' } : { fontWeight: 400, fontSize: '16px' }}>
        <div>
          {selected ? <Check sx={{ fontSize: '16px', marginBottom: '-3px', width: '16px' }} /> : <span style={{ marginLeft: '16px' }} />}
          {menuItem.name}
        </div>
      </MenuItem>
    )
  }

  return (
    <div className={classes.analyticMenuContainer}>
      <Button
        aria-owns={open ? 'simple-menu' : null}
        aria-haspopup='true'
        variant='secondary'
        onClick={handleClick}
        size='small'
        style={{ minWidth: '320px' }}>
        <Typography sx={{ color: '#0768fd', marginTop: '1px', textOverflow: 'ellipsis', overflow: 'hidden', width: '320px' }}>{report.name}</Typography>
        <ArrowDown sx={{ color: '#595959', fontSize: '14px', marginLeft: 'auto' }} />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        style={{ marginTop: 4 }}>
        <div className={classes.panelContent}>
          {configData.menuItems?.map((menuItem: any) => {
            return (
              <div>
                <Typography style={{ fontWeight: 'bold', margin: '15px', fontSize: '24px' }}>
                  {menuItem.name}
                </Typography>
                {menuItem.children
                  .filter((item: any) => item.pbiEntityName)
                  .map((item: any) => (
                    <AnalyticMenuItem menuItem={{ ...item, parentMenuName: menuItem.name }} />
                  ))}
                {menuItem.children
                  .filter((item: any) => item.children)
                  .map((item: any) => (
                    <MenuItemComponent menuItem={item} key={JSON.stringify(item)} />
                  ))}
              </div>
            );
          })}
        </div>
      </Menu>
    </div>
  );
}
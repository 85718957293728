import { createSlice } from '@reduxjs/toolkit';

export const InProgressMessageSlice = createSlice({
  name: 'InProgressMsg',
  initialState: {
    type: '',
    reportName: '',
    open: false,
    embeddedReportName: '',
  },
  reducers: {
    openInProgressMsg(state, { payload }) {
      const { type, reportName } = payload;
      state.type = type;
      state.reportName = reportName;
      state.open = true;
    },
    closeInProgressMsg(state) {
      state.open = false;
      state.type = '';
      state.reportName = '';
    },
    setEmbeddedReportName(state, { payload }) {
      const { reportName } = payload;
      state.embeddedReportName = reportName;
    },
  },
});

export const { closeInProgressMsg, openInProgressMsg, setEmbeddedReportName } =
  InProgressMessageSlice.actions;

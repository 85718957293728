import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCaApiHeaders } from '../../Service/ApiHeaderService';

export const UserStudiesApi = createApi({
    reducerPath: 'userStudiesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/core`,
        headers: getCaApiHeaders()
    }),
    endpoints(builder) {
        return {
            fetchUserStudies: builder.query({
                query: () => {
                    return {
                        url: '/studies',
                        method: 'GET'
                    }
                }
            })
        }
    }
});
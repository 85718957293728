import { App } from 'apollo-react-icons';
import Blade from 'apollo-react/components/Blade';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHtmlContent } from '../../Redux/Slice/AppSwitcherSlice';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { RootState } from '../../Redux/store';
import { getAppSwitcherPage } from '../../Service/AppSwitcherService';
import BannerMessage from '../ModelDialog/BannerMessage';
import './AppSwitcher.css';

const DynamicAppSwitcher = () => {
  const [openPanel, setOpenPanel] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
  const dispatch = useDispatch();
  const { htmlContent }: any = useSelector((state: RootState) => state.AppSwitcher);

  const onClose = () => {
    setOpenPanel(false);
  };
  const onOpen = () => {
    if (errorMessage !== '') {
      dispatch(showBanner({ variant: 'error', message: errorMessage }));
    } else {
      setOpenPanel(true);
    }
  };

  /**Used to set the sanitize html and prevent xss attack clicks */
  useEffect(() => {
    if (htmlContent === '') {
      (async () => {
        const response: any = await getAppSwitcherPage();
        if (!response?.error) {
          const dirtyHtml = response;
          const allowedDomains = 'iqvia.com';
          DOMPurify.addHook('afterSanitizeAttributes', function (node) {
            if ('onclick' in node) {
              const urlClick = node?.getAttribute('onclick');
              if (urlClick) {
                let splitArray = urlClick?.split('=');
                if (!splitArray[1]?.includes(allowedDomains)) {
                  node.removeAttribute('onclick');
                }
              }
            }
          });

          const staniziedOptions = {
            ADD_ATTR: ['onclick'],
            FORCE_BODY: true,
          };
          const cleanHtml = DOMPurify.sanitize(dirtyHtml, staniziedOptions);
          dispatch(updateHtmlContent(cleanHtml));
        } else {
          setErrorMesage(`App Switcher : ${response?.message}`);
        }
      })();
    }
  }, [htmlContent, dispatch]);

  /** Used to set the app switcher close button with react component code */
  const domConentUpdate = (element) => {
    const documentElement = element;
    if (documentElement) {
      const appSwitcherButton = documentElement.querySelector('.switcher-icon');
      appSwitcherButton?.addEventListener('click', onClose.bind(this));
    }
  };

  return (
    <>
      <App
        variant='primary'
        onClick={onOpen}
        data-testid='app-icon'
        style={{ marginTop: '15px', marginRight: '15px', cursor: 'pointer' }}
      />
      <Blade
        className='navigation-panel'
        width={331}
        hasBackdrop
        BackdropProps={{
          onClick: onClose,
          style: { background: 'transparent' },
        }}
        open={openPanel}
        onClose={onClose}
        title=''>
        <div
          ref={(el) => {
            domConentUpdate(el);
          }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
      </Blade>
      <BannerMessage />
    </>
  );
};

export default DynamicAppSwitcher;

import { MsalProvider } from '@azure/msal-react';
import ApolloThemeProvider from 'apollo-react/utils/ApolloThemeProvider';
import dotenv from 'dotenv';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './Redux/store';
import { msalInstance, setActiveMsalAccount } from './Service/AuthService';
import AppRouter from './components/AppComponents/AppRouter';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const App = () => {

  dotenv.config();

  msalInstance.addEventCallback(setActiveMsalAccount);

  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <ApolloThemeProvider>
          <ErrorBoundary>
            <AppRouter />
          </ErrorBoundary>
        </ApolloThemeProvider>
      </MsalProvider>
    </Provider>
  );
};

export default App;
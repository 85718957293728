import Modal from 'apollo-react/components/Modal';
import { NOACCESS_MODEL_MSG, NOACCESS_MODEL_TITLE } from '../../Constants';
import { gotoCore } from '../../Utils';

const NoAccessModel = (props: { isOpen: boolean }) => {
  const { isOpen } = props;

  return (
    <Modal
      disableBackdropClick
      open={isOpen}
      variant='error'
      title={NOACCESS_MODEL_TITLE}
      message={NOACCESS_MODEL_MSG}
      buttonProps={[{ label: 'Home', onClick: gotoCore }]}
      id='error'
    />
  );
};

export default NoAccessModel;

import { createSlice } from '@reduxjs/toolkit';

export const SelfServiceReportSlice = createSlice({
    name: 'selfServiceReportSlice',
    initialState: {
        selfServiceReports: [] as Array<any>
    },
    reducers: {
        setSelfServiceReports(state, { payload }) {
            state.selfServiceReports = payload;
        }
    }
});

export const selfServiceReportReducer = SelfServiceReportSlice.reducer;
export const selfServiceReportAction = SelfServiceReportSlice.actions;
import axios from 'axios';

export const getData = async (url) => {
    return axios.get(url)
        .then(res => res.data)
        .catch(err => {
            return {
                error: true,
                message: (err?.response?.data?.message !== undefined) ? err?.response?.data?.message : 'Cannot fetch config from database'
            }
        });
}

export const patchData = async (url, data) => {
    return axios.patch(url, { ...data })
        .then(res => res.data)
        .catch(err => {
            return {
                error: true,
                message: (err?.response?.data?.message !== undefined) ? err?.response?.data?.message : 'Cannot update config in database'
            }
        });
}

export const deleteDataById = async (url) => {
    return axios.delete(url)
        .then(res => res.data)
        .catch(err => {
            return {
                error: true,
                message: (err?.response?.data?.message !== undefined) ? err?.response?.data?.message : 'Cannot update config in database'
            }
        })
}

export const addData = async (url, data) => {
    return axios.post(url, data)
        .then(res => res.data)
        .catch(err => {
            return {
                error: true,
                message: (err?.response?.data?.message !== undefined) ? err?.response?.data?.message : 'Cannot update config in database'
            }
        })
}
import { makeStyles } from '@mui/styles';
import Divider from 'apollo-react/components/Divider';
import { StudiesAutoCompleteDropdown } from '../../../components/studies/StudiesAutoCompleteDropdown';

const useStyles = makeStyles({
    divContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        position: 'fixed',
        width: 'calc(100% - 70px)'
    }
});

export const ConvAiHeader = () => {
    const classes = useStyles();

    return (
        <div className={classes.divContainer}>
            <StudiesAutoCompleteDropdown width={'45%'} forConvAI />
            <Divider style={{ borderBottomWidth: '2px', borderColor: 'black' }} />
        </div>
    );
}
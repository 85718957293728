//routes
export enum Routes{
    PBI = 'pbi',
    METADATA = 'metadata',
    AUDITS = 'audits',
    CORE = 'core',
    AI= 'ai'
}

//path constants

//pbi
export const TARGET_WORKSPACE = '/workspace/target';
export const REPORT_BY_WORKSPACE_ID = '/workspace/{ workspaceId }/reports';
export const REPORT_BY_WORKSPACE_ID_AND_REPORT_ID = REPORT_BY_WORKSPACE_ID.concat('/{ reportId }');
export const EMBED_REPORT = '/embed/report';
export const EMBED_REPORT_DOWNLOAD = EMBED_REPORT.concat('/download');
export const DOWNLOAD_REPORT_BY_WORKSPACE_ID_AND_REPORT_ID = REPORT_BY_WORKSPACE_ID_AND_REPORT_ID.concat('/download');
export const REPORTS_BY_WORKSPACE_ID_AND_REPORTS_LIMIT = '/workspace/{ workspaceId }/{ reportsLimit }';
export const GET_CONFIG = '/config';
export const GET_TENANT_BI_CLIENTS = GET_CONFIG.concat('/tenantbiclients')
export const GET_UI_CONFIG = GET_CONFIG.concat('/ui-config');

//metadata
export const MENUS = '/menus';
export const DASHBOARDS = '/dashboards';

//audits
export const USER_ACTIONS = '/user-actions'

//core
export const STUDIES = '/studies'

//AI Assistant
export const IMAGE_PATH = '/chats/image/{ path }';
export const SESSIONS_BY_STUDY_PATH = '/chats/study/{ studyId }/sessions';
export const PROCESS_CHAT = '/chats';
export const RENAME_CHAT = '/chats/study/{ studyId }/sessions/{ sessionId }/rename';
export const CHATS_BY_SESSION = '/chats/study/{ studyId }/sessions/{ sessionId }';
export const CHAT_BY_ID = '/chats/{ id }';
export const RATE_CHAT = '/chats/{ chatId }/feedback';
export const DISLIKE_TAGS = '/chats/feedback/dislike-tags'
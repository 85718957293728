import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import FixedBar from 'apollo-react/components/FixedBar';
import Grid from 'apollo-react/components/Grid';
import Loader from 'apollo-react/components/Loader';
import MenuItem from 'apollo-react/components/MenuItem';
import Paper from 'apollo-react/components/Paper';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../components/ModelDialog/CustomModal';
import { CANCEL_LABEL, DEFAULT, SAVE_LABEL } from '../../../Constants';
import { GET_CONFIG, Routes } from '../../../Constants/PathConstants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { getData, patchData } from '../../../Service/AdminService';
import { isStrTrue } from '../../../Utils';
import { getApiPath } from '../../../Utils/PathUtils';

const Generic = () => {
    const URL = getApiPath(GET_CONFIG, Routes.METADATA)
    const [isSaved, setIsSaved] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [genericData, setGenericData] = useState({
        workspaceId: '',
        profileId: '',
        pbiAuthType: '',
        pbiRLSEnabled: false,
        quickHelpEnabled: true,
        quickHelpLink: ''
    });
    const [openSaveDialog, setOpenSaveDialog] = useState(false)

    const handleEditClick = () => {
        setIsEditing(true);
        setIsCancelled(false);
        setIsSaved(false);
    };

    const handleCancelClick = () => {
        setData({});
        setIsCancelled(true);
        setIsSaved(false);
        setIsEditing(false);
    };

    const handleSaveClick = async () => {
        await patchData(URL, data);
        setIsSaved(true);
        dispatch(showBanner({
            variant: 'success',
            message: `Successfully updated the settings`
        }))
        setData({});
        setIsEditing(false);
        setOpenSaveDialog(false);
    };

    const handleSaveDialogClose = () => {
        handleCancelClick();
        setOpenSaveDialog(false);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await getData(URL);
            if (!response?.error) {
                setLoading(false);
                setGenericData({
                    workspaceId: response.workspaceId,
                    profileId: response.profileId,
                    pbiAuthType: response.pbiAuthType,
                    pbiRLSEnabled: isStrTrue(response.pbiRLSEnabled),
                    quickHelpEnabled: response.quickHelp?.enabled,
                    quickHelpLink: response.quickHelp?.link
                });
            } else {
                setLoading(false);
                dispatch(
                    showBanner({
                        variant: 'error',
                        message: response.message,
                    })
                );
            }
        })();
    }, [isSaved, isCancelled]);

    const styles = {
        row: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '24px',
            paddingRight: '24px'
        },
        gridItem: {
            minWidth: '25%'
        },
        header: {
            height: '80px'
        }
    };
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    return (
        <React.Fragment>
            {loading ? (
                <div>
                    <Loader isInner />
                </div>
            ) : (
                <div>
                    {isEditing ? (
                        <div className={classes.header}>
                            <FixedBar title="Managment of Tenant & App Settings">
                                <Button onClick={handleCancelClick} variant='secondary' size='small'>
                                    Cancel
                                </Button>
                                <Button onClick={() => { setOpenSaveDialog(true) }} variant='primary' size='small'>
                                    Save
                                </Button>
                            </FixedBar>
                        </div>
                    ) : (
                        <div className={classes.header}>
                            <FixedBar title="Managment of Tenant & App Settings">
                                <Button onClick={handleEditClick} variant='primary' size='small'>
                                    Edit
                                </Button>
                            </FixedBar>
                        </div>
                    )}
                    <div style={{ marginTop: '1rem' }}>
                        <Paper>
                            <div>
                                <div className={classes.row}>
                                    <Grid item md={2} style={{ paddingTop: 30, minWidth: '10%' }}>
                                        <Typography fontWeight={'bold'}>Tenant Settings</Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.gridItem}>
                                        <TextField
                                            value={genericData.workspaceId}
                                            label='Workspace Id'
                                            onChange={(e) => {
                                                genericData.workspaceId = e.target.value;
                                                setData({ ...data, workspaceId: e.target.value });
                                            }}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={1}>
                                    </Grid>
                                    <Grid item md={4} className={classes.gridItem}>
                                        <TextField
                                            value={genericData.profileId}
                                            label='Profile Id'
                                            onChange={(e) => {
                                                genericData.profileId = e.target.value;
                                                setData({ ...data, profileId: e.target.value });
                                            }}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                </div>
                                <div className={classes.row}>
                                    <Grid item md={2} style={{ paddingTop: 30, minWidth: '10%' }}>
                                        <Typography fontWeight={'bold'}>   App Settings</Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.gridItem} >
                                        <Select
                                            label="PowerBI Authentication Type"
                                            value={genericData.pbiAuthType}
                                            onChange={(e) => {
                                                genericData.pbiAuthType = genericData.pbiAuthType === 'User' ? 'SP' : 'User';
                                                genericData.pbiRLSEnabled = genericData.pbiAuthType === 'User' ? false : true;
                                                setData({ ...data, pbiAuthType: genericData.pbiAuthType, pbiRLSEnabled: genericData.pbiRLSEnabled });
                                            }}
                                            canDeselect={false}
                                            disabled={!isEditing}
                                            fullWidth
                                        >
                                            <MenuItem value="SP">{'App Owns Data'}</MenuItem>
                                            <MenuItem value="User">{'User Owns Data'}</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={1}>
                                    </Grid>
                                    <Grid item md={4} className={classes.gridItem}>
                                        <Select
                                            label="PowerBI Row-Level Security"
                                            value={genericData.pbiRLSEnabled}
                                            onChange={(e) => {
                                                genericData.pbiRLSEnabled = !genericData.pbiRLSEnabled;
                                                setData({ ...data, pbiRLSEnabled: genericData.pbiRLSEnabled });
                                            }}
                                            canDeselect={false}
                                            disabled
                                            fullWidth
                                        >
                                            <MenuItem value={true}>{'enabled'}</MenuItem>
                                            <MenuItem value={false}>{'disabled'}</MenuItem>
                                        </Select>
                                    </Grid>
                                </div>
                                <div className={classes.row} style={{ marginBottom: '1rem' }}>
                                    <Grid item md={2} style={{ paddingTop: 30, minWidth: '10%' }} >
                                        <Typography fontWeight={'bold'}></Typography>
                                    </Grid>
                                    <Grid item md={4} className={classes.gridItem}>
                                        <Select
                                            label="Quick Help?"
                                            value={genericData.quickHelpEnabled}
                                            onChange={(e) => {
                                                genericData.quickHelpEnabled = !genericData.quickHelpEnabled;
                                                setData({
                                                    ...data,
                                                    quickHelp: {
                                                        enabled: genericData.quickHelpEnabled,
                                                        link: genericData.quickHelpLink
                                                    }
                                                });
                                            }}
                                            canDeselect={false}
                                            disabled={!isEditing}
                                            fullWidth
                                        >
                                            <MenuItem value={true}>{'enabled'}</MenuItem>
                                            <MenuItem value={false}>{'disabled'}</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={1}>
                                    </Grid>
                                    {genericData.quickHelpEnabled ? (
                                        <Grid item md={4} className={classes.gridItem}>
                                            <TextField
                                                value={genericData.quickHelpLink}
                                                label={'Quick Help Link'}
                                                onChange={(e) => {
                                                    genericData.quickHelpLink = e.target.value;
                                                    setData({
                                                        ...data, quickHelp: {
                                                            enabled: genericData.quickHelpEnabled,
                                                            link: e.target.value
                                                        }
                                                    });
                                                }}
                                                placeholder='Link'
                                                fullWidth
                                                disabled={!isEditing}
                                            />
                                        </Grid>
                                    ) : <Grid item md={4} className={classes.gridItem} />}
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            )
            }
            <CustomModal
                display={openSaveDialog}
                handleClose={handleSaveDialogClose}
                title='Do you really want to save the changes?'
                type={DEFAULT}
                variant={DEFAULT}
                buttonProps={[
                    { label: CANCEL_LABEL, onClick: handleSaveDialogClose },
                    { label: SAVE_LABEL, onClick: handleSaveClick },
                ]}
            />
        </React.Fragment >
    );
};

export default Generic;
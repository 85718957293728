import Export from 'apollo-react-icons/Export';
import FilePdf from 'apollo-react-icons/FilePdf';
import FilePpt from 'apollo-react-icons/FilePpt';
import Card from 'apollo-react/components/Card';
import IconButton from 'apollo-react/components/IconButton';
import Popper from 'apollo-react/components/Popper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DOWNLOAD_SUCCESS_MSG, PDF, PPTX } from '../../Constants';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { closeInProgressMsg, openInProgressMsg } from '../../Redux/Slice/InProgressMessageSlice';
import { downloadExportedFile } from '../../Service/ExportService';

const ExportReport = (props: {
  isEmbedPage: boolean
  workspaceId: string,
  reportId: string,
  datasetId: string,
  signedRequest: string,
  selectedReport: any
}) => {

  const { workspaceId, reportId, isEmbedPage, datasetId, signedRequest, selectedReport } =
    props;
  const [anchorEl, setAnchorEl] = useState(false);
  const dispatch = useDispatch();
  const handleDownloadClick = async (fileFormat: string) => {
    setAnchorEl(false);
    dispatch(
      openInProgressMsg({
        type: fileFormat,
        reportName: selectedReport?.name,
      })
    );
    let downloadResponse: any;
    if (!isEmbedPage) {
      downloadResponse = await downloadExportedFile(reportId, datasetId, fileFormat, workspaceId);
    } else {
      downloadResponse = await downloadExportedFile(
        reportId,
        datasetId,
        fileFormat,
        workspaceId,
        signedRequest
      );
    }
    if (downloadResponse !== undefined) {
      dispatch(closeInProgressMsg());
    }
    if (!downloadResponse?.error) {
      dispatch(
        showBanner({
          variant: "success",
          message: DOWNLOAD_SUCCESS_MSG,
        })
      );
      return downloadResponse;
    } else {
      dispatch(
        showBanner({
          variant: "error",
          message: downloadResponse.message,
        })
      );
    }
  };
  return (
    <div onMouseLeave={() => setAnchorEl(false)}>
      <Export
        onClick={(e) => {
          setAnchorEl(!anchorEl ? e.currentTarget : false);
        }}
        style={{
          marginLeft: '1rem',
          marginTop: '0.25rem',
          color: '#0557d5',
          cursor: 'pointer',
        }}
        data-testid='export-icon'>
        {`${!anchorEl ? 'Open' : 'Close'} popper`}
      </Export>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <Card
          interactive
          style={{
            padding: 3,
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}>
          <IconButton
            onClick={() => {
              handleDownloadClick(PDF);
            }}>
            <FilePdf
              style={{
                color: '#0557d5',
              }}
              data-testid='pdf-icon'
            />
          </IconButton>
          <IconButton
            onClick={() => {
              handleDownloadClick(PPTX);
            }}>
            <FilePpt
              style={{
                color: '#0557d5',
              }}
              data-testid='pptx-icon'
            />
          </IconButton>
        </Card>
      </Popper>
    </div>
  );
};

export default ExportReport;
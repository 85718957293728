import Close from 'apollo-react-icons/Close';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import CardActions from 'apollo-react/components/CardActions';
import CardContent from 'apollo-react/components/CardContent';
import CardHeader from 'apollo-react/components/CardHeader';
import Chip from 'apollo-react/components/Chip';
import ChipGroup from 'apollo-react/components/ChipGroup';
import IconButton from 'apollo-react/components/IconButton';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BANNER_ERROR_TEXT, ERROR, FEEDBACK_CARD_SUBTITLE, FEEDBACK_CARD_TITLE, FEEDBACK_COMMENT_HELPER_TEXT, FEEDBACK_ERROR_MESSAGE } from '../../../Constants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { AiApi } from '../../../Redux/apis/AiApi';
import { ScrollToBottom } from '../../../components/generic/ScrollToBottom';


export const AIFeedbackForm = (props) => {
    const { chat, setShowFeedbackForm } = props;
    const { data: dislikeTagsData, isFetching, error } = AiApi.useFetchDislikeTagsQuery({});
    const [rateChat] = AiApi.useRateChatMutation();
    let dislikeTags: Array<Object> = [];
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [comment, setComment] = useState('');

    if (!isFetching && !error) {
        dislikeTags = dislikeTagsData
    } else if (error) {
        dispatch(showBanner({
            variant: ERROR,
            message: BANNER_ERROR_TEXT,
        }))
    }

    const handleChange = (e, val) => {
        if (value === val) {
            setValue(0)
        } else {
            setValue(val)
        }
    }

    const handleCommentInputChange = (e) => {
        setComment(e.target.value)
    }

    const handleSubmit = async () => {
        await rateChat({
            chatId: chat.id, body: {
                ratingAccuracy: 1,
                dislikeTagId: value,
                comments: comment
            }
        }).unwrap()
            .then(r => {
                return true
            })
            .catch(e => {
                dispatch(showBanner({
                    variant: ERROR,
                    message: FEEDBACK_ERROR_MESSAGE
                }))
                return false
            })
        setShowFeedbackForm(false)
    }

    return (
        <Card tabIndex={0} style={{ backgroundColor: '#E6E6E6' }}>
            <CardHeader
                title={<Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{FEEDBACK_CARD_TITLE}</Typography>}
                subheader={<Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{FEEDBACK_CARD_SUBTITLE}</Typography>}
                action={
                    <IconButton
                        size={'small'}
                        data-testid='close-button'
                        onClick={() => { setShowFeedbackForm(false) }}
                    >
                        <Close />
                    </IconButton>
                }
            />
            <CardContent>
                <ChipGroup value={value} onChange={handleChange}>
                    {dislikeTags.map((e: any) => (
                        <Chip
                            sx={{
                                ".css-xfeaxr-MuiSvgIcon-root": {
                                    display: 'none'
                                },
                            }}
                            color={value === e.tagId ? 'primary' : 'white'}
                            key={e.tagId}
                            label={e.name}
                            value={e.tagId}
                        />))}
                </ChipGroup>
                <TextField
                    value={comment}
                    onChange={handleCommentInputChange}
                    label="Comment"
                    data-testid='comment'
                    helperText={FEEDBACK_COMMENT_HELPER_TEXT}
                    fullWidth
                />
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                    size="small"
                    data-testid="submit"
                    style={{ marginRight: 10 }}
                    disabled={value === 0 && !comment}
                >
                    {'Submit'}
                </Button>
            </CardActions>
            <ScrollToBottom dependencyList={[]} />
        </Card>
    )
}
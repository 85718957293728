import { HomeLayout } from "../Home/HomeLayout";
import { AiAssistPage } from "./ConvAiPage";

const AiAssistRoutes = [
  {
    path: '/ai-assistant',
    component: AiAssistPage,
    layout: HomeLayout,
    exact: true,
    authentication: true,
  },
];

export default AiAssistRoutes;
import Cookies from 'js-cookie';
import moment from 'moment';

export const getUserName = () => {
  const FIRST_NAME: string = Cookies.get('user.first_name');
  const LAST_NAME: string = Cookies.get('user.last_name');
  return `${FIRST_NAME} ${LAST_NAME}`;
};

export const getLoginDate = () => {
  const date = moment.unix(Cookies.get('user.last_login_ts'));
  const isDateValid = date.isValid();
  return isDateValid ? moment(date).format('LLL') : moment().format('LLL');
};

export const getUserId = () => {
  return Cookies.get('user.id');
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCaApiHeaders } from '../../Service/ApiHeaderService';

export const ConfigApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/pbi/config`,
        headers: getCaApiHeaders()
    }),
    endpoints: (builder) => ({
        fetchConifg: builder.query({
            query: () => {
                return {
                    url: '/',
                    method: 'GET'
                }
            }
        }),
        fetchUiConfig: builder.query({
            query: () => {
                return {
                    url: '/ui-config',
                    method: 'GET'
                }
            }
        })

    })
});
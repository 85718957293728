import axios from "axios"

export const getDashboards = async(url) => {
    return axios.get(url)
    .then( res => res.data)
    .catch(err => err)
  }
  
export const deleteDashboardById = async(url) => {
    return axios.delete(url)
    .then( res => res.data)
    .catch(err => err)
  }
  
export const editDashboard = async(url, editRow) => {
    return axios.patch(url, editRow)
    .then( res => res.data)
    .catch(err => err)
  }
  
export const createDashboard = async(url, newRow) => {
    return axios.post(url, newRow)
    .then( res => res.data)
    .catch(err => err)
  }
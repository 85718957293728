import { createSlice } from '@reduxjs/toolkit';
import * as powerbi from 'powerbi-client';

export interface ReportState {
  report: powerbi.Report | null;
}

const initialState: ReportState = {
  report: null,
};
export const ReportSlice = createSlice({
  name: 'Report',
  initialState,
  reducers: {
    reportConfig(state, { payload }) {
      state.report = payload;
    },
  },
});

export const { reportConfig } = ReportSlice.actions;

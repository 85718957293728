import adminRoutes from './Pages/Admin/AdminRoutes';
import AnalyzePageRoutes from './Pages/Analyze/Routes';
import AiAssistRoutes from './Pages/ConvAI/ConvAiRoutes';
import embedRoutes from './Pages/Embed/Routes';
import homeRoutes from './Pages/Home/Routes';
import logoutRoutes from './Pages/Logout/Routes';

const routes: any = [...adminRoutes, ...homeRoutes, ...embedRoutes, ...logoutRoutes, ...AnalyzePageRoutes, ...AiAssistRoutes];

export default routes;

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { LOGOUT_PATH } from '../../Constants';
import { Logout } from '../../Service/LogoutService';
import { addEventListeners, removeEventListeners } from '../../Utils';
import SessionTimeoutDialog from './SessionTimeoutDialog';

let countdownInterval;
let timeout;

const SessionTimeout = ({ isAuthenticated, timeOut }) => {
  const history = useHistory();
  const location = useLocation();
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );
  const inActivityTime = parseInt(timeOut as string);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const gotoLoginPage = async () => {
    clearSessionInterval();
    let path = LOGOUT_PATH;
    setTimeoutModalOpen(false);
    await Logout();
    history.push({
      pathname: path,
      state: {
        logout: true,
      },
    });
  };
  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        if (isAuthenticated() && currentPath !== 'logout') {
          setTimeoutModalOpen(true);
        }
      }, inActivityTime);

    const createTimeout2 = () => {
      const delay = 1000 * 1;
      if (isAuthenticated() && currentPath !== 'logout') {
        setTimeout(() => {
          let countDown = 30;
          setTimeoutModalOpen(true);
          setTimeoutCountdown(countDown);
          countdownInterval = setInterval(() => {
            if (countDown > 0) {
              setTimeoutCountdown(--countDown);
            } else {
              gotoLoginPage();
            }
          }, 1000);
        }, delay);
      }
    };

    // Initialization

    timeout = timeoutModalOpen ? createTimeout2() : createTimeout1();

    const listener = () => {
      if (!timeoutModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutModalOpen]);

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };
  return (
    <SessionTimeoutDialog
      countdown={timeoutCountdown}
      onContinue={handleContinue}
      onLogout={() => gotoLoginPage()}
      open={timeoutModalOpen}
    />
  );
};

export default SessionTimeout;

export enum SpecialChar {
    SLASH = '/'
}

export enum AuditCategory {
    REPORT = 'report',
    AI_ASSISTANT = 'ai-assistant'
}

export enum AuditSubCategory {
    VIEW_REPORT = 'view-report',
    SELFSERVICE = 'self-service',
    PRINT_REPORT = 'print-report',
    DOWNLOAD_PNG = 'download-png',
    DOWNLOAD_CSV = 'download-csv',
    DOWNLOAD_PDF = 'download-pdf'
}

export enum AuditAction {
    VIEW = 'view',
    CREATE = 'create',
    UPDATE = 'update',
    PRINT = 'print',
    DOWNLOAD = 'download'
}

export enum MainMenuPermissionNames {
    ADMIN = 'Admin',
    AI_ASSISTANT = "AI Assistant"
}
import { List, ListItemButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import OpenNew from 'apollo-react-icons/OpenNew';
import Pencil from 'apollo-react-icons/Pencil';
import Box from 'apollo-react/components/Box';
import Divider from 'apollo-react/components/Divider';
import IconButton from 'apollo-react/components/IconButton';
import Search from 'apollo-react/components/Search';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BANNER_ERROR_TEXT, EARLIER, ERROR, MY_CONVERSATIONS, NEW_CONVERSATION, NO_CONVERSATIONS_INFO, PENCIL_ICON_TOOLTIP_TEXT, SEARCH_BY_TITLE, STUDY, TODAY } from '../../../Constants';
import { AiApi } from '../../../Redux/apis/AiApi';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { setCurrentConversationContext, setQueryText, setRenameInput } from '../../../Redux/Slice/GenAiSlice';
import { RootState, store } from '../../../Redux/store';

const useStyles = () => {
    const getSessionsHeight = () => {
        return '255px'
    }

    return makeStyles({
        sessions: {
            paddingBottom: '10px',
            height: `calc(100vh - ${getSessionsHeight()}) !important`,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '0.5px slategrey',
                borderRadius: '10px'
            }
        },

        leftPanel: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            width: 332,
            padding: 10
        }
    });
}

const currentDate = new Date();
const timeAgo = (prevDate: string) => {
    const momentDate = moment(prevDate);
    const currentMomentDate = moment(currentDate);
    const diff = currentDate.getTime() - new Date(prevDate).getTime();
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    switch (true) {
        case diff < minute:
            return `now`;
        case diff < hour:
            const minutes = Math.round(diff / minute);
            return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
        case diff < day:
            const hours = Math.round(diff / hour);
            return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
        case diff > day:
            const days = Math.round(diff / day);
            if (momentDate.isSame(currentMomentDate, 'week')) {
                return `${days} ${days > 1 ? 'days' : 'day'} ago`;
            } else {
                return new Date(prevDate).toLocaleDateString([], { day: 'numeric', month: 'short', year: 'numeric' })
            }
        default:
            return "";
    }
};

const PrimaryTextComponent = (props) => {
    const { conversation, studyId, hover, setHover, sessionNameInput, setSessionNameInput } = props;
    const [renameSession] = AiApi.useRenameSessionMutation();
    const renameInput = useSelector((state: RootState) => state.genAi.renameInput)
    const dispatch = useDispatch();
    const handleEditIconClick = () => {
        dispatch(setRenameInput(conversation.sessionLabel))
        setHover(false)
        setSessionNameInput(true)
    };
    const inputRef = useRef<any>(null);
    const handleSave = async () => {
        const input = store.getState().genAi.renameInput;
        if (input && input !== conversation.sessionLabel) {
            await renameSession({ studyId, sessionId: conversation.sessionId, sessionName: input }).then(r => {
                console.log(r);
            }).catch(e => {
                dispatch(showBanner({
                    variant: ERROR,
                    message: BANNER_ERROR_TEXT
                }))
            });
        }
        setSessionNameInput(false);
    }
    const handleSessionNameChange = async (e) => {
        if (inputRef.current && !inputRef.current?.contains(e.target)) {
            handleSave()
        }
    };
    const handleEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSave()
        }
    }
    useEffect(() => {
        if (sessionNameInput) {
            document.addEventListener('mousedown', handleSessionNameChange);
        } else {
            document.removeEventListener('mousedown', handleSessionNameChange)
        }
        return () => {
            document.removeEventListener('mousedown', handleSessionNameChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionNameInput])
    if (sessionNameInput) {
        return (
            <TextField
                ref={inputRef}
                value={renameInput} onChange={(e) => {
                    dispatch(setRenameInput(e.target.value))
                }}
                data-testid={'session-name-input'}
                fullWidth
                sx={{
                    ".css-vgiytg-MuiInputBase-root-MuiInput-root": {
                        marginTop: '0px'
                    },
                    margin: '0px',
                    padding: '0px',
                    ".css-1xrr4qs-MuiInputBase-input-MuiInput-input.MuiInput-input": {
                        height: '28px'
                    }
                }}
                size={'small'}
                onKeyDown={handleEnterKeyPress} />
        )
    } else {
        return (
            <Box
                onMouseEnter={() => {
                    setHover(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="body1"
                        color={hover ? '#013D98' : '#333333'}
                        style={{
                            marginRight: '16px',
                            fontSize: '16px',
                            fontWeight: 500
                        }}>
                        {conversation.sessionLabel}
                    </Typography>
                    {hover &&
                        <Tooltip variant='light' title={PENCIL_ICON_TOOLTIP_TEXT} placement='bottom'>
                            <IconButton
                                onClick={handleEditIconClick}
                                style={{
                                    color: '#4D4D4D', margin: '0px', padding: '0px', height: '20px', width: '20px', marginTop: '2.5px'
                                }}
                                size={'extraSmall'}
                                data-testid={'pencil-icon'}
                            >
                                <Pencil />
                            </IconButton>
                        </Tooltip>}
                </div>
            </Box>
        )
    }
};

const ConversationTile = (props) => {
    const { conversation } = props;
    const { dts } = conversation;
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);
    const [sessionNameInput, setSessionNameInput] = useState(false);
    const currentConversationContext = useSelector((state: RootState) => state.genAi.currentConversationContext);
    const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);

    return (
        <ListItemButton
            key={conversation.sessionId}
            style={{ padding: sessionNameInput ? '0px 8px 10px 8px' : '5px 8px' }}
            sx={{
                "&.Mui-selected": {
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #001433'
                }
            }}
            selected={currentConversationContext?.sessionId === conversation.sessionId}
            onClick={() => {
                if (currentConversationContext?.sessionId !== conversation.sessionId) {
                    dispatch(setCurrentConversationContext({
                        sessionId: conversation.sessionId,
                        studyId: selectedStudies[0],
                        isNewConversation: false
                    }))
                    dispatch(setQueryText(''))
                }
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                {currentConversationContext.sessionId === conversation.sessionId ?
                    <PrimaryTextComponent
                        conversation={conversation}
                        studyId={selectedStudies[0]}
                        hover={hover}
                        setHover={setHover}
                        sessionNameInput={sessionNameInput}
                        setSessionNameInput={setSessionNameInput}
                    /> :
                    <Typography style={{ fontWeight: 500 }} color={hover ? '#013D98' : '#333333'}>{conversation.sessionLabel}</Typography>}
                <Typography style={{ fontSize: '14px', fontWeight: 500 }} color={hover ? '#013D98' : '#4D4D4D'}>{timeAgo(dts)}</Typography>
            </div>
            <ChevronRight fontSize={'small'} />
        </ListItemButton>
    );
};

const HistoryComponent = () => {
    const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
    const { data: conversationsData, isError } = AiApi.useFetchSessionsQuery({ studyId: selectedStudies[0] })
    const dispatch = useDispatch();
    const currentConversationContext = useSelector((state: RootState) => {
        const currConvContext = state.genAi.currentConversationContext
        if (!currConvContext) {
            dispatch(setCurrentConversationContext({
                sessionId: '',
                studyId: selectedStudies[0],
                isNewConversation: true
            }))
        }
        return currConvContext
    });
    const [searchVal, setSearchVal] = useState('');
    const [filteredConversations, setFilteredConversations] = useState<Array<any>>([]);
    const classes = useStyles()();

    const handleNewSession = () => {
        dispatch(setQueryText(''));
        dispatch(setCurrentConversationContext({
            sessionId: '',
            studyId: selectedStudies[0],
            isNewConversation: true
        }));
    };

    useEffect(() => {
        if (conversationsData) {
            setFilteredConversations(conversationsData)
        }
    }, [conversationsData]);

    useEffect(() => {
        dispatch(setQueryText(''))
        dispatch(setCurrentConversationContext({
            sessionId: '',
            studyId: selectedStudies[0],
            isNewConversation: true
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudies])

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
        setFilteredConversations(conversationsData.filter((s: any) => s.sessionLabel.includes(e.target.value)));
        dispatch(setCurrentConversationContext({
            sessionId: '',
            studyId: selectedStudies[0],
            isNewConversation: false
        }))
    };

    if (isError) {
        dispatch(showBanner({
            variant: ERROR,
            message: BANNER_ERROR_TEXT
        }))
    }

    return (
        <React.Fragment>
            {conversationsData && <div className={classes.leftPanel}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant={'title1'}>{MY_CONVERSATIONS}</Typography>
                        {conversationsData.length !== 0 &&
                            <Tooltip variant='light' title={NEW_CONVERSATION} placement='bottom'>
                                <span>
                                    <IconButton
                                        disabled={currentConversationContext?.isNewConversation}
                                        onClick={handleNewSession}
                                        data-testid='new-conversation'
                                    >
                                        <OpenNew size='small' />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        }
                    </div>
                    <Typography variant={'body1'}> {STUDY + selectedStudies[0]}</Typography>
                    <Divider />
                    {conversationsData.length !== 0 && <Search placeholder={SEARCH_BY_TITLE} value={searchVal} style={{ width: '100%' }} onChange={handleSearch} data-testid='search'/>}
                </div>
                <div className={classes.sessions}>
                    {conversationsData.length ? (
                        <div>
                            {filteredConversations.filter((s: any) => moment(s.dts).isSame(currentDate, 'day')).length !== 0 &&
                                <div>
                                    <Typography variant={'h4'} style={{ fontSize: '16px' }}>
                                        {TODAY}
                                    </Typography>
                                    <List>
                                        {filteredConversations.filter((s: any) => moment(s.dts).isSame(currentDate, 'day')).map(s => (<ConversationTile key={s.sessionId} conversation={s} />))}
                                    </List>
                                </div>}
                            {filteredConversations.filter((s: any) => !moment(s.dts).isSame(currentDate, 'day')).length !== 0 &&
                                <div>
                                    <Typography variant={'h4'} style={{ fontSize: '16px' }}>
                                        {EARLIER}
                                    </Typography>
                                    <List>
                                        {filteredConversations.filter((s: any) => !moment(s.dts).isSame(currentDate, 'day')).map(s => (<ConversationTile key={s.sessionId} conversation={s} />))}
                                    </List>
                                </div>}
                        </div>
                    ) : (
                        <div style={{ marginTop: 100 }}>
                            <Typography variant={'body2'}>{NO_CONVERSATIONS_INFO}</Typography>
                        </div>)}
                </div>
            </div>}
        </React.Fragment >
    );
};

export default HistoryComponent;
import { makeStyles } from '@mui/styles';

export const useAppStyles = makeStyles({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        margin: '70px 25px 0px 25px'
    },
    divContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    divRow: {
        display: 'flex',
        gap: '15px',
        alignItems: 'center'
    },
    disabledDiv: {
        opacity: 0.3,
        pointerEvents: 'none'
    }
});
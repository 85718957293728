import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import IconButton from 'apollo-react/components/IconButton';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Loader from 'apollo-react/components/Loader';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IntegerFilter } from '../../../components/Filters/IntegerFilter';
import { TextFieldFilter } from '../../../components/Filters/TextFieldFilter';
import CustomModal from '../../../components/ModelDialog/CustomModal';
import { CANCEL_LABEL } from '../../../Constants';
import { DASHBOARDS, MENUS, Routes } from '../../../Constants/PathConstants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { deleteDataById, getData } from '../../../Service/AdminService';
import { getApiPath } from '../../../Utils/PathUtils';
import { MenuDialog } from './MenuDialog';
import SubmenuItems from './SubMenuItems';

const MenuManagement = () => {
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [addSubMenu, setAddSubMenu] = useState(false);
  const [editSubMenu, setEditSubMenu] = useState(false);
  const [editMenu, setEditMenu] = useState(false);
  const [addMenu, setAddMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [dashboards, setDashboards] = useState([])
  const [currentMenu, setCurrentMenu] = useState({ id: 5, name: '', submenu: [] })
  const [isDelete, setIsDelete] = useState(false);
  const URL = getApiPath(MENUS, Routes.METADATA)
  const dispatch = useDispatch();
  const [rows, setRows] = useState([
    {
      id: 8473,
      name: 'Clinical',
      displayOrder: 1,
      submenu: [
        {
          id: 123,
          name: 'Lab',
          reportId: '7897989009r568',
          displayOrder: 1,
          description: 'Test',
          edit: 'true',
          mapToDashboard: true,
          dashboardName: 'Clinical',
          print: 'true',
          export: 'true',
        },
        {
          id: 456,
          name: 'Adverse Events',
          reportId: 'tfyt7y789446',
          displayOrder: 2,
          dashboardName: 'Clinical',
          description: 'Test',
          mapToDashboard: false,
          edit: 'false',
          print: 'false',
          export: 'false',
        },
      ],
    },
  ]);

  const ActionCell = ({ row }) => {
    const { setEditMenu, setCurrentMenu, setIsDelete, setAddSubMenu } = row;
    const handleEditMenu = () => {
      setEditMenu(true);
      setCurrentMenu(row)
    };
    const handleDelete = () => {
      setCurrentMenu(row)
      setIsDelete(true)
    }
    const handleAddSubMenu = () => {
      setCurrentMenu(row)
      setAddSubMenu(true)
    }

    const menuItems = [
      {
        text: 'Edit',
        onClick: () => handleEditMenu(),
      },
      {
        text: 'Add Submenu',
        onClick: () => handleAddSubMenu(),
      },
      {
        text: 'Delete',
        onClick: handleDelete,
      },
    ];
    return (
      <Tooltip title='Actions' disableFocusListener>
        <IconMenuButton id='actions' menuItems={menuItems} size='small'>
          <EllipsisVerticalIcon />
        </IconMenuButton>
      </Tooltip>
    );
  };

  const mapMenusWithChildren = (menuItems: any, parentId) => {
    const menus: Array<any> = [];
    menuItems.forEach((item: any, index) => {
      if (item.parentId === parentId) {
        const menuItem = {
          ...item,
          edit: item.config.permissions?.edit,
          print: item.config.permissions?.print,
          export: item.config.permissions?.export,
          dashboardId: item.dashboard?.id,
          dashboardName: item.dashboard?.name,
          handleToggleRow,
          setEditMenu,
          setCurrentMenu,
          setEditSubMenu,
          setAddSubMenu,
          setIsDelete,
          key: item.id,
        };
        const children = mapMenusWithChildren(menuItems, item.id)
        if (children.length > 0) {
          menuItem.submenu = children
        }
        menus.push(menuItem)
      }
    })
    return menus
  }
  useEffect(() => {
    (async () => {
      const URL = getApiPath(DASHBOARDS, Routes.METADATA)
      const dashboardResponse = await getData(URL)
      if (!dashboardResponse.error) {
        setDashboards(dashboardResponse)
      } else {
        dispatch(
          showBanner({
            variant: 'error',
            message: dashboardResponse.message,
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getData(URL);
      if (!response?.error) {
        setLoading(false);
        setRows(mapMenusWithChildren(response, null))
      } else {
        setLoading(false);
        dispatch(
          showBanner({
            variant: 'error',
            message: response.message,
          })
        );
      }
    })();
  }, [render]);

  const handleToggleRow = (rowId) => {
    setExpandedRows((rowsExpanded) =>
      rowsExpanded.includes(rowId)
        ? rowsExpanded.filter((id) => id !== rowId)
        : [...rowsExpanded, rowId]
    );
  };

  const CustomButtonHeader = (props) => {
    return (
      <div>
        <Button
          size='small'
          variant='secondary'
          icon={Filter}
          style={{ marginRight: 15 }}
          onClick={props.toggleFilters}
          data-testid='filter-button'>
          Filter
        </Button>
        <Button size='small' variant='primary' onClick={() => setAddMenu(true)}>
          Add new Menu
        </Button>
      </div>
    );
  };

  const ExpandCell = ({ row: { id, handleToggleRow, expanded, submenu } }) => {
    const [tooltipTitle, Icon] = useMemo(
      () => (expanded ? ['Collapse', ChevronDown] : ['Expand', ChevronRight]),
      [expanded]
    );
    return (
      <div style={{ width: 15 }}>
        {submenu?.length ? (
          <Tooltip title={tooltipTitle} disableFocusListener>
            <IconButton id='expand' size='small' onClick={() => handleToggleRow(id)}>
              <Icon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const DetailRow = ({ row }) => {
    if (row.submenu) {
      return <SubmenuItems submenu={row.submenu} {...row} />;
    } else {
      return null
    }
  };

  const columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell,
      width: 50,
    },
    {
      header: 'ID',
      accessor: 'id',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('id'),
    },
    {
      header: 'Name',
      accessor: 'name',
      sortFunction: compareStrings,
      filterFunction: createStringSearchFilter('name'),
      filterComponent: TextFieldFilter,
    },
    {
      header: 'Display Order',
      accessor: 'displayOrder',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('displayOrder'),
    },
    {
      accessor: 'action',
      customCell: ActionCell,
      align: 'right',
    },
  ];
  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Loader isInner />
        </div>
      ) : (
        <div>
          <Table
            title='Menus'
            subtitle='Manage your Menus'
            columns={columns}
            rows={rows.map((row) => ({
              ...row,
              expanded: expandedRows.includes(row.id) ? true : false,
            }))}
            ExpandableComponent={DetailRow}
            CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
            rowsPerPageOptions={[5, 10, 15, 'All']}
            tablePaginationProps={{
              labelDisplayedRows: ({ from, to, count }) =>
                `${count === 1 ? 'Menu' : 'Menus'} ${from}-${to} of ${count}`,
              truncate: true,
            }}
          />
          {isDelete && (
            <CustomModal
              display={true}
              handleClose={() => setIsDelete(false)}
              title='Do you want to delete?'
              type='error'
              variant='error'
              buttonProps={[
                { label: CANCEL_LABEL, onClick: () => setIsDelete(false) },
                {
                  label: 'Delete', onClick: () => {
                    if (!currentMenu.submenu) {
                      deleteDataById(`${URL}/${currentMenu.id}`)
                      setTimeout(() => { setRender(!render) }, 1000)
                      dispatch(showBanner({
                        variant: 'success',
                        message: `Successfully deleted ${currentMenu.name} menu`
                      }))
                    } else {
                      dispatch(showBanner({
                        variant: 'info',
                        message: 'delete submenu items first',
                      }))
                    }
                    setIsDelete(false)
                  }
                },
              ]}
            />
          )}
          {addSubMenu && (
            <CustomModal
              title='Add Sub Menu'
              display={true}
              handleClose={() => setAddSubMenu(false)}
              hideButtons={true}
              body={<MenuDialog onCancel={setAddSubMenu} parentData={currentMenu} dashboardData={dashboards} type={'add'} render={render} setRender={setRender} URL={URL} />}
            />
          )}
          {editMenu && (
            <CustomModal
              title='Edit Menu'
              display={true}
              handleClose={() => setEditMenu(false)}
              hideButtons={true}
              body={<MenuDialog onCancel={setEditMenu} parentData={currentMenu} dashboardData={dashboards} type={'edit menu'} render={render} setRender={setRender} URL={URL} />} />
          )}
          {editSubMenu && (
            <CustomModal
              title='Edit Sub Menu'
              display={true}
              handleClose={() => setEditSubMenu(false)}
              hideButtons={true}
              body={<MenuDialog onCancel={setEditSubMenu} parentData={currentMenu} dashboardData={dashboards} type={'edit'} render={render} setRender={setRender} URL={URL} />}
            />
          )}
          {addMenu && (
            <CustomModal
              title='Add Menu'
              display={true}
              handleClose={() => setAddMenu(false)}
              hideButtons={true}
              body={<MenuDialog onCancel={setAddMenu} parentData={rows[0]} dashboardData={dashboards} type={'add menu'} render={render} setRender={setRender} URL={URL} />}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default MenuManagement;
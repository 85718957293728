import { useIsAuthenticated } from '@azure/msal-react';
import Loader from 'apollo-react/components/Loader';
import * as models from 'powerbi-models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_CUSTOM_DASHBOARD, SELF_SERVICE_REPORT } from '../../Constants';
import { EMBED_REPORT, REPORT_BY_WORKSPACE_ID_AND_REPORT_ID, Routes } from '../../Constants/PathConstants';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { RootState } from '../../Redux/store';
import { getCAReports, getEmbeddedReports } from '../../Service/ReportService';
import { getApiPath } from '../../Utils/PathUtils';
import BannerMessage from '../ModelDialog/BannerMessage';
import PBIconfig from './PBIconfig';

export const Report = (props?: any) => {
  const [reportConfig, setReportConfig] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const isPBIUserAuthenticated = useIsAuthenticated();
  const selectedReport = useSelector((state: RootState) => state.selectedReportSlice.selectedReport);
  const resetReport = useSelector((state: RootState) => state.selectedReportSlice.resetTime);
  const { pbiAuthType } = useSelector((state: RootState) => state.InitialConfig)
  const embedToken = useSelector((state: RootState) => state.embedTokenSlice.embedToken)

  const createConfigForCreate: any = {
    type: 'create',
    datasetId: reportConfig.datasetId,
    embedUrl: reportConfig.embedUrl,
    accessToken: reportConfig.token,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.Edit,
  };

  const getCAReportsWithToken = (URL) => {
    if (selectedReport?.parentMenuName === SELF_SERVICE_REPORT &&
      selectedReport?.name !== NEW_CUSTOM_DASHBOARD) {
      return getCAReports(URL, pbiAuthType);
    } else {
      if (embedToken) {
        return getCAReports(URL.concat(`?requireToken=${false}`), pbiAuthType, embedToken);
      } else {
        return getCAReports(URL, pbiAuthType);
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (!props?.isEmbed) {
        const URL: any = getApiPath(REPORT_BY_WORKSPACE_ID_AND_REPORT_ID, Routes.PBI, {
          'workspaceId': selectedReport.workspaceId,
          'reportId': selectedReport.reportId
        })
        const response = await getCAReportsWithToken(URL)
        if (!response?.error) {
          setReportConfig(response);
          setError(false);
        } else {
          dispatch(
            showBanner({
              variant: 'error',
              message: response.message,
            })
          );
          setError(true);
        }
      } else {
        const URL: any = getApiPath(EMBED_REPORT, Routes.PBI)
        const payload = {
          signed_request: props?.jwtToken !== false ? props?.jwtToken : '',
          pbiAuthType: props?.authType,
        };
        const response = await getEmbeddedReports(URL, payload);
        if (!response?.error) {
          setReportConfig(response);
          setError(false);
        } else {
          dispatch(
            showBanner({
              variant: 'error',
              message: response.message,
            })
          );
          setError(true);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, isPBIUserAuthenticated, resetReport]);

  if (error) {
    return (
      <React.Fragment>
        <BannerMessage top_margin={props?.isEmbed ? true : false} />
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        {loading ? (
          <div>
            <Loader isInner />
          </div>
        ) : (
          <PBIconfig
            isEmbedPage={props?.isEmbed}
            authType={props?.isEmbed ? props?.authType : pbiAuthType}
            signedRequest={props?.jwtToken}
            configReport={reportConfig}
            createConfigForCreate={createConfigForCreate}
            permissions={selectedReport?.permissions}
            isCustomReport={
              selectedReport?.parentMenuName === SELF_SERVICE_REPORT &&
              selectedReport?.name !== NEW_CUSTOM_DASHBOARD
            }
          />
        )}
      </React.Fragment>
    );
};

export default Report;

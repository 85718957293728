import { makeStyles } from '@mui/styles';
import PaperPlane from 'apollo-react-icons/PaperPlane';
import Box from 'apollo-react/components/Box';
import IconButton from 'apollo-react/components/IconButton';
import Panel from 'apollo-react/components/Panel';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { AI_ASSIST_PLACE_HOLDER_TEXT, BANNER_ERROR_FOR_STUDY, BANNER_ERROR_TEXT, ConverseHelpPageConstants, ERROR, FOOTER_TEXT, NO_CONV_SELECTED, PAPERPLANE_ICON_TOOLTIP_TEXT } from '../../../Constants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { setCurrentConversationContext, setHistoryPanelOpened, setQueryText, setScrollToId } from '../../../Redux/Slice/GenAiSlice';
import { AiApi } from '../../../Redux/apis/AiApi';
import { RootState } from '../../../Redux/store';
import ChatComponent from './ChatComponent';
import HistoryComponent from './HistoryComponent';

const useStyles = (historyPanelOpened) => {
    const getHeight = () => {
        return '115px'
    };
    const getWidth = () => {
        if (historyPanelOpened) {
            return '382px'
        } else {
            return '72px'
        }
    }
    return makeStyles({
        body: {
            display: 'flex',
            marginTop: '52px',
            position: 'fixed',
            width: '100%',
            height: `calc(100% - ${getHeight()}) !important`,
            paddingBottom: '10px'
        },
        rightPanel: {
            display: 'flex',
            flexDirection: 'column',
            width: `calc(100% - ${getWidth()}) !important`,
            padding: 10
        },
        chats: {
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '0.5px slategrey',
                borderRadius: '10px'
            }
        },
        noConvSelected: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: `calc(100% - ${getWidth()}) !important`
        }
    });
}

const ConverseHelpPage = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    }}>
        <Typography variant={'h1'}>{ConverseHelpPageConstants.AI_ASSIST_TITLE}</Typography>
        <Typography>{ConverseHelpPageConstants.AI_ASSIST_DESCRIPTION}</Typography>
    </div>
)

export const ConvAiBody = () => {
    const dispatch = useDispatch();
    const { historyPanelOpened, queryText } = useSelector((state: RootState) => state.genAi);
    const selectedStudiesForAi = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
    const currentConversationContext = useSelector((state: RootState) => {
        const currConvContext = state.genAi.currentConversationContext
        if (!currConvContext) {
            dispatch(setCurrentConversationContext({
                sessionId: '',
                studyId: selectedStudiesForAi[0],
                isNewConversation: true
            }))
        }
        return currConvContext
    });
    const classes = useStyles(historyPanelOpened)();

    const handleOpen = () => {
        dispatch(setHistoryPanelOpened(true))
    }

    const handleClose = () => {
        dispatch(setHistoryPanelOpened(false))
    }

    const [processChat] = AiApi.useProcessChatMutation();

    const handleGo = async () => {
        const body: any = {
            queryText: queryText,
            studyId: selectedStudiesForAi[0],
            modelName: "gpt-4"
        }
        if (currentConversationContext?.isNewConversation) {
            body.sessionLabel = queryText
        } else {
            body.sessionId = currentConversationContext?.sessionId
        }

        dispatch(setQueryText(''));

        const processChatResp = await processChat(body);
        if ('data' in processChatResp) {
            dispatch(setCurrentConversationContext({
                sessionId: processChatResp.data.sessionId,
                studyId: selectedStudiesForAi[0],
                isNewConversation: false
            }));
            dispatch(setScrollToId(Date.now()));
        } else {
            const err = processChatResp.error;
            dispatch(setCurrentConversationContext({
                sessionId: '',
                studyId: selectedStudiesForAi[0],
                isNewConversation: true
            }));
            dispatch(showBanner({
                variant: ERROR,
                message: 'status' in err && err.status === 416 ? BANNER_ERROR_FOR_STUDY : BANNER_ERROR_TEXT
            }));
        }
    };

    const isPaperPlanEnabled = () => queryText?.trim().length > 5;

    const handleEnterKeyPress = async (e) => {
        if (e.key === "Enter" && isPaperPlanEnabled()) {
            await handleGo()
        }
    }

    return (
        <Box className={classes.body}>
            <Panel width={332} open={historyPanelOpened} onOpen={handleOpen} onClose={handleClose}>
                <HistoryComponent />
            </Panel>
            {currentConversationContext?.sessionId === '' && !currentConversationContext?.isNewConversation ?
                <div className={classes.noConvSelected}>
                    <Typography>{NO_CONV_SELECTED}</Typography>
                </div>
                : <div className={classes.rightPanel}>
                    {!currentConversationContext?.isNewConversation ?
                        <ChatComponent /> :
                        <div className={classes.chats}>
                            <ConverseHelpPage />
                        </div>
                    }
                    <div>
                        <div style={{ display: 'flex' }}>
                            <TextField style={{ width: '100%' }}
                                value={queryText}
                                placeholder={AI_ASSIST_PLACE_HOLDER_TEXT}
                                onChange={(e) => { dispatch(setQueryText(e.target.value)) }}
                                onKeyDown={handleEnterKeyPress}
                                data-testid={'query-input'}
                            />
                            <Tooltip variant='light' title={PAPERPLANE_ICON_TOOLTIP_TEXT} placement='bottom'>
                                <span>
                                    <IconButton
                                        style={{ marginTop: '20px' }}
                                        disabled={!isPaperPlanEnabled()}
                                        onClick={handleGo}
                                        data-testid={'go-button'}>
                                        <PaperPlane size='small' />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div >
                        <Typography style={{ fontsize: '14px', fontWeight: 500, color: '#444444' }}>{FOOTER_TEXT}</Typography>
                    </div>
                </div>}
        </Box>
    )
}
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import InfoIcon from 'apollo-react-icons/Info';
import Card from 'apollo-react/components/Card';
import CardHeader from 'apollo-react/components/CardHeader';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DISABLE_STUDIES_LENGTH_ARR } from '../../Constants';
import { AuditAction, AuditCategory, AuditSubCategory } from '../../Constants/Enums';
import { SelectedReportSlice } from '../../Redux/Slice/SelectedReportSlice';
import { RootState } from '../../Redux/store';
import logUserAction from '../../Service/AuditLogService';

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex'
  },
  cardStyle: {
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: ' 26px',
    width: '550px'
  },
  cardHeader: {
    height: '70px'
  }
});

export const DashboardCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory();
  const [enableTooltip, setEnableTooltip] = useState(false);

  const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
  const { name, parentMenuName, description, reportId, workspaceId, permissions, clientName, isSingleStudyReport, thumbnailImageLink } = props.item;
  const handleCardDisabled = !DISABLE_STUDIES_LENGTH_ARR.includes(selectedStudies.length) && isSingleStudyReport;

  const handleOpenTooltip = (e: any) => {
    e.stopPropagation();
    setEnableTooltip(true);
  };

  const handleCloseTooltip = (e: any) => {
    e.stopPropagation();
    setEnableTooltip(false);
  };

  const handleCardClick = (e: any) => {
    if (selectedStudies.length !== 1 && isSingleStudyReport) {
      console.log(e)
    } else {
      dispatch(SelectedReportSlice.actions.setSelectedReport({
        name,
        parentMenuName,
        permissions,
        clientName,
        reportId,
        workspaceId,
        isSingleStudyReport,
      }));
      history.push('/analyze');
      logUserAction(AuditCategory.REPORT, AuditSubCategory.VIEW_REPORT, AuditAction.VIEW,
        { reportId: reportId, workspaceId: workspaceId, reportName: name }
      );
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
      <Card interactive className={classes.cardStyle} sx={props.style}
        onClick={handleCardClick} disabled={handleCardDisabled}>
        <CardHeader
          className={classes.cardHeader}
          title={<Typography style={{ fontSize: '16px', fontWeight: '500' }}>{name}</Typography>}
          action={
            <div style={{ marginTop: '7.5px' }}>
              <ClickAwayListener onClickAway={handleCloseTooltip}>
                <Tooltip title={description} disableFocusListener disableTouchListener
                  onClose={handleCloseTooltip} open={enableTooltip} placement={'right'}>
                  <IconButton disabled={!description} onClick={handleOpenTooltip} size='small' style={{ alignSelf: 'self-end' }} data-testid='description-tooltip'>
                    <InfoIcon style={{ display: !description ? 'none' : '' }} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </div>
          }
        />
        {
          thumbnailImageLink && <img src={thumbnailImageLink} alt={name} hidden={false} width={'100%'} />
        }
      </Card>
    </Grid>
  );
}
import { EMBEDDED_PATH } from '../../Constants';
import Embed from './Embed';
import { EmbedLayout } from './EmbedLayout';

const embedRoutes = [
  {
    path: EMBEDDED_PATH,
    component: Embed,
    layout: EmbedLayout,
    exact: true,
    authentication: false,
  },
];

export default embedRoutes;

import { createSlice } from '@reduxjs/toolkit';

export const GenAiSlice = createSlice({
    name: 'generativeAiSlice',
    initialState: {
        converseEnabled: false,
        currentConversationContext: undefined as any,
        historyPanelOpened: true,
        queryText: '',
        renameInput: '',
        scrollToId: ''
    },
    reducers: {
        setCurrentConversationContext(state, { payload }) {
            state.currentConversationContext = payload
        },
        setConverseEnabled(state, { payload }) {
            state.converseEnabled = payload
        },
        setHistoryPanelOpened(state, { payload }) {
            state.historyPanelOpened = payload
        },
        setQueryText(state, { payload }) {
            state.queryText = payload
        },
        setRenameInput(state, { payload }) {
            state.renameInput = payload
        },
        setScrollToId(state, { payload }) {
            state.scrollToId = payload
        }
    }
});

export const {
    setCurrentConversationContext,
    setConverseEnabled,
    setHistoryPanelOpened,
    setQueryText,
    setRenameInput,
    setScrollToId
} = GenAiSlice.actions;
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppStyles } from "../../AppStyles";
import { NEW_SELF_SERVICE_REPORT, SELF_SERVICE_REPORT } from '../../Constants';
import { SelectedReportSlice } from '../../Redux/Slice/SelectedReportSlice';
import { DashboardCard } from './DashboardCard';

export const SelfServiceGrid = (props) => {
    const appClasses = useAppStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const items = props.child.children.filter((child: any) => child.name !== NEW_SELF_SERVICE_REPORT).map((item: any) => <DashboardCard item={item} style={{ backgroundColor: '#E9E9E9' }} key={JSON.stringify(item)} />)

    const newSelfServiceItem = props.child.children.find((child: any) => child.name === NEW_SELF_SERVICE_REPORT);

    const handleNewButtonClick = (e) => {
        dispatch(SelectedReportSlice.actions.setSelectedReport({
            name: newSelfServiceItem.name,
            parentMenuName: SELF_SERVICE_REPORT,
            permissions: newSelfServiceItem.permissions,
            clientName: newSelfServiceItem.clientName,
            reportId: newSelfServiceItem.reportId,
            workspaceId: newSelfServiceItem.workspaceId,
            isSingleStudyReport: newSelfServiceItem.isSingleStudyReport,
        }));
        history.push('/analyze');
    }

    return (
        <div className={appClasses.divContainer}>
            <div className={appClasses.divRow}>
                <span>
                    <Typography variant={'h2'}>
                        {props.child.name}
                    </Typography>
                </span>
                <span>
                    <Button variant="secondary" size="small" onClick={handleNewButtonClick}>
                        Create report
                    </Button>
                </span>
            </div>
            <Typography>
                {props.child.description}
            </Typography>
            <Grid container>
                {items}
            </Grid>
        </div>
    )
}
import axios from 'axios';
import { LOGOUT_ERROR } from '../Constants';

const getDomainName = () => {
  const urlParts = window.location.hostname.split('.');
  return urlParts.slice(-(urlParts.length, 2)).join('.');
};

export function deleteAllCookies() {
  const domain = getDomainName();
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};domain=${domain}`);
  });
  localStorage.clear();
  return true;
}

export const Logout = async () => {
  const URL = `${process.env.REACT_APP_CORE_SERVER_URL}/logout`;
  return axios
    .get(URL, {
      transformRequest: [
        (data, headers: any) => {
          delete headers?.userId;
          delete headers?.['pbi-accesstoken'];
          delete headers?.['sel-studies'];
          headers['sys-name'] = 'CDI';
          return data;
        },
      ],
    })
    .then(async (res) => {
      if (res.data) {
        return deleteAllCookies();
      }
      return false;
    })
    .catch((error) => {
      return {
        error: true,
        message:
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : LOGOUT_ERROR,
      };
    });
};

import { HOME_PATH } from '../../Constants';
import { Home } from './Home';
import { HomeLayout } from './HomeLayout';

const homeRoutes = [
  {
    path: HOME_PATH,
    component: Home,
    layout: HomeLayout,
    exact: true,
    authentication: true,
  },
];

export default homeRoutes;

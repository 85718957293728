import MenuItem from 'apollo-react/components/MenuButton';
import Select from 'apollo-react/components/Select';
import { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

export const CommentBox = ({ embeddedReport }) => {
    const [selectedVisualName, setSelectedVisualName] = useState('');
    const [allVisuals, setAllVisuals] = useState([{
        name: '',
        title: ''
    }]);
    useEffect(() => {
        (async () => {
            if (embeddedReport) {
                embeddedReport.on('loaded', async (event) => {
                    const pages = await embeddedReport.getPages();
                    let page = pages.filter(p => p.isActive)[0];
                    const visuals = await page.getVisuals();
                    setAllVisuals(visuals)
                });
                embeddedReport?.off("commandTriggered");
                embeddedReport?.on("commandTriggered", function (event) {
                    let commandDetails: any = event.detail;
                    setSelectedVisualName(commandDetails.visual.name)
                });
            }
        })()
    }, [embeddedReport])
    const [formState, setFormState] = useState({
        username: '',
        comment: '',
        visualName: '',
        bookmarkState: ''
    });
    const [comments, setComments] = useState([formState]);

    const handleChange = (e) => {
        setSelectedVisualName(e.target.value)
    }
    
    const handleCommentClick = async (comment) => {
        const result = await embeddedReport.bookmarksManager.applyState(comment.bookmarkState)
        console.log(result);   
    }

    const users = [
        {
            id: "arun",
            display: "Arun Keerthi",
        },
        {
            id: "ganesh",
            display: "Ganesh Devidiga",
        },
        {
            id: "akhil",
            display: "Akhil Kondapalkala",
        },
        {
            id: "shyam",
            display: "Shyam Aniyan",
        }
    ];

    const submit = async () => {
        if (formState.username === '' || formState.comment === '') {
            alert('Please fill in all fields');
        }
        const capturedBookmark = await embeddedReport.bookmarksManager.capture();
        setComments((allComments) => [
            ...allComments,
            {
                username: formState.username,
                comment: formState.comment,
                visualName: selectedVisualName,
                bookmarkState: capturedBookmark.state
            },
        ]);
        setFormState({
            username: '',
            comment: '',
            visualName: '',
            bookmarkState: ''
        });
    };

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    return (
        <div>
            <Select
                label="visual selected"
                value={selectedVisualName}
                onChange={handleChange}
                placeholder="Select item..."
                fullWidth
            >
                {allVisuals.map((visual) => {
                    return (<MenuItem key={visual.name} value={visual.name}>{visual.title}</MenuItem>)
                })}

            </Select>
            <section>
                <h2>Comments:</h2>
                <input
                    type="text"
                    value={formState.username}
                    onChange={(e) =>
                        setFormState({ ...formState, username: e.target.value })
                    }
                    placeholder="Input Your Name"
                />
                <MentionsInput
                    placeholder="Add Comment. Use '@' for mention"
                    value={formState.comment}
                    onChange={(e) =>
                        setFormState({ ...formState, comment: e.target.value })
                    }
                >
                    <Mention data={users} />
                </MentionsInput>
                <button onClick={submit}>Submit</button>
            </section>
            {comments.length === 0 ? (
                null
            ) : (
                <section>
                    {comments.filter(comment => comment.visualName === selectedVisualName).map((comment, i) => (
                        <button onClick={() => handleCommentClick(comment)} key={i}>
                            <p>
                                {comment.username} on {date}
                            </p>
                            <h2>{comment.comment}</h2>
                        </button>
                    ))}
                </section>
            )}
        </div>
    )
}
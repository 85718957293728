import axios from "axios";
import { BLOB, DOWNLOAD_ERROR_MSG, DOWNLOAD_SUCCESS_MSG } from "../Constants";
import { DOWNLOAD_REPORT_BY_WORKSPACE_ID_AND_REPORT_ID, EMBED_REPORT_DOWNLOAD, Routes } from "../Constants/PathConstants";
import { getApiPath } from "../Utils/PathUtils";

export const downloadExportedFile = async (
  reportId: string,
  datasetId: string,
  fileFormat: string,
  workspaceId: string,
  signedRequest?: string
) => {
  const URL = signedRequest ? getApiPath(EMBED_REPORT_DOWNLOAD, Routes.PBI) : getApiPath(DOWNLOAD_REPORT_BY_WORKSPACE_ID_AND_REPORT_ID, Routes.PBI, { 'workspaceId': workspaceId, 'reportId': reportId });
  const fileExtension = fileFormat.toLowerCase();
  return axios
    .post(
      URL,
      {
        format: fileFormat,
        reportId: reportId,
        datasetId: datasetId,
        signed_request: signedRequest,
      },
      { responseType: BLOB }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report.`.concat(fileExtension)); //or any other file extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      return {
        message: DOWNLOAD_SUCCESS_MSG
      };
    })
    .catch((error) => {
      return {
        error: true,
        message: DOWNLOAD_ERROR_MSG,
      };
    });
}
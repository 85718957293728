import axios from 'axios';
import { GENERAL_ERROR_MSG, USER_ID } from '../Constants';
import { checkCookieAvailble, getCoreApiHeaders } from './ApiHeaderService';

export const getAppSwitcherPage = async () => {
  const URL = `${process.env.REACT_APP_CORE_SERVER_URL}/v1/api/webpage/server`;
  const payload = {
    fileName: 'app-switcher',
    userId: checkCookieAvailble(USER_ID),
    productName: 'Admin',
  };
  const headers = await getCoreApiHeaders();
  const instance = axios.create();
  delete instance.defaults.headers.common['userId'];
  return instance
    .post(URL, payload, { headers: headers })
    .then(async (res) => {
      return res.data;
    })
    .catch((error) => {
      return {
        error: true,
        message: error?.message ? error.message : GENERAL_ERROR_MSG,
      };
    });
};

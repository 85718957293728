import axios from 'axios';
import { Dispatch } from 'react';
import { GENERAL_ERROR_MSG } from '../Constants';
import { SelectedReportSlice } from '../Redux/Slice/SelectedReportSlice';
import { getAccessToken } from './AuthService';

const setEmbedToken = async (authType: string | undefined, data: any, embedToken: string | undefined) => {
  if (authType === 'User') {
    return getAccessToken()
  } else {
    if (!embedToken) {
      return data.embedToken
    } else {
      return embedToken
    }
  }
}

const getConfigData = async (data, authType?: string, embedToken?: string) => {
  let config: any = { type: 'report' };
  if (data !== undefined && data?.response?.status === undefined) {
    if (data.embedReports !== undefined) {
      config.reportId = data.embedReports[0].reportId;
      config.embedUrl = data.embedReports[0].embedUrl;
    }
    if (data.reportFilters !== undefined) {
      config.reportFilters = data.reportFilters;
    }
    if (data.embedToken !== undefined) {
      config.token = await setEmbedToken(authType, data, embedToken)
    }
    if (data.embedUrl !== undefined) {
      config.embedUrl = data.embedUrl;
    }
    if (data.datasetId !== undefined) {
      config.datasetId = data.datasetId;
    }
    return config;
  } else {
    return {
      message:
        data?.response?.data?.message !== undefined
          ? data.response.data.message
          : GENERAL_ERROR_MSG,
      error: true,
    };
  }
};

export const getEmbeddedReports = async (url, payload) => {
  const data = await axios
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return getConfigData(data, payload.pbiAuthType);
};

export const getCAReports = async (url, pbiAuthType, embedToken?) => {
  const data = await axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return getConfigData(data, pbiAuthType, embedToken);
};

export const resetCurrentReportData = async (dispatch: Dispatch<any>) => {
  dispatch(SelectedReportSlice.actions.resetSelectedReportData());
}
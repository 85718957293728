import Header from '../../components/Header/Header';
import SessionTimeout from '../../components/SessionTimeout/SessionTimeout';
import { isAuthorised } from '../../Service/AuthService';

type Props = {
  children?: JSX.Element;
};

export const AdminLayout = (props: Props) => {
  const TIME_OUT: string | undefined = process.env.REACT_APP_SESSION_TIMEOUT_TIME;

  return (
    <div>
      <SessionTimeout isAuthenticated={isAuthorised} timeOut={TIME_OUT} />
      <Header />
      <div style={{ marginTop: '55px' }}>{props.children}</div>
    </div>
  );
};

import React from "react";
import { useAppStyles } from "../../AppStyles";
import Report from "../../components/PowerBi/Report";
import { AnalyzeHeader } from "./AnalyzeHeader";

export const AnalyzePage = () => {
  const appClasses = useAppStyles();
  return (
    <React.Fragment>
      <div className={appClasses.pageContainer}>
        <AnalyzeHeader />
        <Report />
      </div>
    </React.Fragment>
  );
};
import { useIsAuthenticated } from '@azure/msal-react';
import Loader from 'apollo-react/components/Loader';
import axios from 'axios';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SELF_SERVICE_REPORT } from '../../Constants';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { EmbedTokenSlice } from '../../Redux/Slice/EmbedTokenSlice';
import { InitialConfigSlice } from '../../Redux/Slice/InitialConfigSlice';
import { MainMenuPermissionsSlice } from '../../Redux/Slice/MainMenuPermissionsSlice';
import { SelectedReportSlice } from '../../Redux/Slice/SelectedReportSlice';
import { SelectedStudiesSlice } from '../../Redux/Slice/SelectedStudiesSlice';
import { ConfigApi } from '../../Redux/apis/ConfigApi';
import { UserStudiesApi } from '../../Redux/apis/UserStudiesApi';
import { RootState } from '../../Redux/store';
import { getCaApiHeaders } from '../../Service/ApiHeaderService';
import { authenticatePBIUser, isAuthorised } from '../../Service/AuthService';
import { getSelfServiceMenu } from '../../Service/SelfServiceMenuService';
import { getMenuData } from '../../Service/UserConfigService';
import Header from '../../components/Header/Header';
import BannerMessage from '../../components/ModelDialog/BannerMessage';
import NoAccessModel from '../../components/ModelDialog/NoAccessModel';
import SessionTimeout from '../../components/SessionTimeout/SessionTimeout';
import './style.css';


type Props = {
  children?: JSX.Element;
};

export const HomeContext = createContext({});
const TIME_OUT: string | undefined = process.env.REACT_APP_SESSION_TIMEOUT_TIME;

export const HomeLayout = (props: Props) => {
  const [configData, setConfigData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isNoAccessModelOpen, setIsNoAccessModelOpen] = useState(false);
  const [reportMenuResponse, setReportMenuResponse] = useState<any>([]);
  const [isNewReportAdded, setIsNewReportAdded] = useState(false);
  const [isAuthFailure, setIsAuthFailure] = useState(false);
  const [targetWorkspaceId, setTargetWorkspaceId] = useState('');
  const dispatch = useDispatch();
  const isPBIUserAuthenticated = useIsAuthenticated();
  const { pbiAuthType } = useSelector((state: RootState) => state.InitialConfig)
  const selectedReport = useSelector((state: RootState) => state.selectedReportSlice.selectedReport)
  const selectedStudiesWasSet = useSelector((state: RootState) => state.selectedStudiesSlice.wasSet);
  const { data: initialConfigApiData } = ConfigApi.useFetchConifgQuery({});
  const { data: uiConfigApiData, isFetching: uiConfigApiFetching, error: uiConfigApiErr } = ConfigApi.useFetchUiConfigQuery({});
  const { data: studiesApiData, isFetching: studiesApiFetching, error: studiesApiErr } = UserStudiesApi.useFetchUserStudiesQuery({});

  dispatch(MainMenuPermissionsSlice.actions.setMainMenuPermissions(uiConfigApiData?.mainMenuPermissions));
  dispatch(InitialConfigSlice.actions.setPbiAuthType(initialConfigApiData?.pbiAuthType));
  dispatch(InitialConfigSlice.actions.setQuickHelp(initialConfigApiData?.quickHelp));

  axios.interceptors.request.use(
    async (request: any) => {
      request.headers = { ...request.headers, ...getCaApiHeaders() };
      return request;
    },
    (error) => {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  const contextData: any = {
    configData,
    isNewReportAdded,
    targetWorkspaceId,
    setIsNewReportAdded,
    setTargetWorkspaceId,
  };

  const getSelfServiceMenuData = useCallback(
    async (id: string) => {
      const response = await getSelfServiceMenu(dispatch, id);
      if (response?.error) {
        dispatch(showBanner({ variant: ERROR, message: response.message }));
      }
      return response;
    },
    [dispatch]
  );

  const getMenuItemsData = async () => {
    if (reportMenuResponse?.length === 0) {
      const uiConfigData = JSON.parse(JSON.stringify(uiConfigApiData))
      const uiConfigMenuData = getMenuData(uiConfigData.menuItems).menu
      if (uiConfigMenuData === undefined || uiConfigMenuData?.length === 0) {
        setIsNoAccessModelOpen(true);
      } else {
        let customReportMenu;
        const otherMenu = uiConfigMenuData?.filter((item) => item.name === SELF_SERVICE_REPORT);
        const reportMenu = uiConfigMenuData?.filter((item) => item.name !== SELF_SERVICE_REPORT);
        setReportMenuResponse(reportMenu);

        if (uiConfigData?.targetWorkspace?.id) {
          customReportMenu = await getSelfServiceMenuData(uiConfigData.targetWorkspace.id);
        }
        const selfServiceMenu = otherMenu?.map((el) => {
          return {
            ...el,
            children: customReportMenu?.length
              ? el.children.concat(customReportMenu)
              : el.children,
          };
        });

        setConfigData({
          menuItems: [...reportMenu, ...selfServiceMenu],
          userPermissions: uiConfigData.userPermissions,
          targetWorkspace: uiConfigData.targetWorkspace,
        });

        let defaultReportData = {
          reportId: '',
          workspaceId: '',
          parentId: '',
          permissions: {},
          name: '',
          parentName: '',
          clientName: '',
          isSingleStudyReport: false
        };

        if (uiConfigMenuData[0].children === undefined) {
          defaultReportData.reportId = uiConfigMenuData[0].reportId;
          defaultReportData.name = uiConfigMenuData[0].name;
          defaultReportData.workspaceId = uiConfigMenuData[0].workspaceId;
          defaultReportData.permissions = uiConfigMenuData[0].permissions;
          defaultReportData.clientName = uiConfigMenuData[0].clientName;
          defaultReportData.parentName = uiConfigMenuData[0].name;
          defaultReportData.isSingleStudyReport = uiConfigMenuData[0].isSingleStudyReport
        } else if (uiConfigMenuData[0].children[0].children === undefined) {
          defaultReportData.reportId = uiConfigMenuData[0]?.children[0].reportId;
          defaultReportData.name = uiConfigMenuData[0]?.children[0].name;
          defaultReportData.workspaceId = uiConfigMenuData[0]?.children[0].workspaceId;
          defaultReportData.parentId = uiConfigMenuData[0].reportId;
          defaultReportData.permissions = uiConfigMenuData[0]?.children[0].permissions;
          defaultReportData.clientName = uiConfigMenuData[0]?.children[0].clientName;
          defaultReportData.isSingleStudyReport = uiConfigMenuData[0]?.children[0].isSingleStudyReport
          defaultReportData.parentName = uiConfigMenuData[0].name;
        } else {
          defaultReportData.reportId = uiConfigMenuData[0]?.children[0].children[0].reportId;
          defaultReportData.name = uiConfigMenuData[0]?.children[0].children[0].name;
          defaultReportData.workspaceId = uiConfigMenuData[0]?.children[0].children[0].workspaceId;
          defaultReportData.parentId = uiConfigMenuData[0].children[0].reportId;
          defaultReportData.permissions = uiConfigMenuData[0]?.children[0].children[0].permissions;
          defaultReportData.clientName = uiConfigMenuData[0]?.children[0].children[0].clientName;
          defaultReportData.isSingleStudyReport = uiConfigMenuData[0]?.children[0].children[0].isSingleStudyReport
          defaultReportData.parentName = uiConfigMenuData[0].children[0].name;
        }
        if (uiConfigData.embedToken) {
          dispatch(EmbedTokenSlice.actions.setEmbedToken(uiConfigData.embedToken))
        }

        if (!selectedReport.reportId) {
          dispatch(SelectedReportSlice.actions.setSelectedReport({
            reportId: defaultReportData.reportId,
            name: defaultReportData.name,
            clientName: defaultReportData.clientName,
            workspaceId: defaultReportData.workspaceId,
            parentName: defaultReportData.parentName,
            parentId: defaultReportData.parentId !== '' ? defaultReportData.parentId : null,
            permissions: defaultReportData.permissions,
          }));
        }
      }
    } else {
      let customReportMenu;
      const menuResponse = configData?.menuItems;
      if (targetWorkspaceId !== '') {
        customReportMenu = await getSelfServiceMenuData(targetWorkspaceId);
      }
      const otherMenu = menuResponse?.filter((item) => item.name === SELF_SERVICE_REPORT);
      const reportMenu = menuResponse?.filter((item) => item.name !== SELF_SERVICE_REPORT);
      setReportMenuResponse(reportMenu);
      const selfServiceMenu = otherMenu?.map((el) => {
        return {
          ...el,
          children: customReportMenu?.length
            ? el.children
              ?.filter((item) => item.name === 'New Self Service Report')
              .concat(customReportMenu)
            : el.children,
        };
      });
      setConfigData({
        menuItems: [...reportMenu, ...selfServiceMenu],
        userPermissions: configData.userPermissions,
        targetWorkspace: configData.targetWorkspace,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (pbiAuthType !== '') {
      if (!isPBIUserAuthenticated && pbiAuthType === 'User') {
        if (!isAuthFailure) {
          authenticatePBIUser(setIsAuthFailure);
        } else {
          dispatch(
            showBanner({
              variant: ERROR,
              message: 'Error while authenticating powerbi user',
            })
          );
        }
      } else {
        if (!studiesApiFetching && !studiesApiErr && !uiConfigApiFetching && !uiConfigApiErr && studiesApiData.length !== 0) {
          if (!selectedStudiesWasSet) {
            dispatch(SelectedStudiesSlice.actions.addStudy(studiesApiData[0]))
            dispatch(SelectedStudiesSlice.actions.setWasSet(true))
          }
          getMenuItemsData()
        } else if ((!uiConfigApiFetching && uiConfigApiErr) || (!studiesApiFetching && (studiesApiErr || studiesApiData.length === 0))) {
          setLoading(false)
          setIsNoAccessModelOpen(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPBIUserAuthenticated, isNewReportAdded, isAuthFailure, pbiAuthType, uiConfigApiFetching]);
  return (
    <div>
      <BannerMessage top_margin={true} />
      <SessionTimeout isAuthenticated={isAuthorised} timeOut={TIME_OUT} />
      <Header />
      {loading ? (
        <div>
          <Loader isInner />
        </div>
      ) : (
        <HomeContext.Provider value={contextData}>
          {isNoAccessModelOpen ? (
            <NoAccessModel isOpen={isNoAccessModelOpen} />
          ) : (
            <div>
              {props.children}
            </div>
          )}
        </HomeContext.Provider>
      )}
    </div>
  );
};
import TextField from 'apollo-react/components/TextField';

export const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin='none'
      size='small'
    />
  );
};

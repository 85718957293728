import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from 'apollo-react/components/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, STUDIES_DROPDOWN_HELPER_TEXT, STUDIES_PACEHOLDER_TEXT, STUDYS_LABEL } from '../../Constants';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { SelectedStudiesSlice } from '../../Redux/Slice/SelectedStudiesSlice';
import { UserStudiesApi } from '../../Redux/apis/UserStudiesApi';
import { RootState } from '../../Redux/store';
import { isStrTrue } from '../../Utils';

const useStyles = makeStyles({
    studyAutoCompleteContainer: {
        display: 'flex',
        gap: '5px'
    },
    studyAutoCompleteContainerForZeroStudies: {
        display: 'flex',
        gap: '5px',
        marginTop: '25px'
    }
});

const formatData = (studiesArray: Array<string>) => {
    return studiesArray.map((study) => {
        return { label: study };
    });
}

export const StudiesAutoCompleteDropdown = (props) => {
    const { width, interactive, studies, setStudies, report, forConvAI } = props;
    const classes = useStyles();
    let userStudies: Array<Object> = [];
    const dispatch = useDispatch();
    const { data: studiesApiData, isFetching, error } = UserStudiesApi.useFetchUserStudiesQuery({});
    const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
    let customStudies: string[] = [];

    if (!isFetching && !error) {
        userStudies = formatData(Array.from(studiesApiData));
        if (forConvAI && isStrTrue(process.env.REACT_APP_CONV_AI_CUSTOM_STUDY_ENABLED) && process.env.REACT_APP_CONV_AI_CUSTOM_STUDIES) {
            customStudies = process.env.REACT_APP_CONV_AI_CUSTOM_STUDIES.split(',');
            customStudies.forEach(std => userStudies.push({ label: std.trim() }));
        }
    } else if (error) {
        dispatch(showBanner({
            variant: ERROR,
            message: 'Exception occurred while fetching studies',
        }))
    }

    const handleChange = (event, values) => {
        let studiesApiDataArr: Array<string> = [];
        if (forConvAI && process.env.REACT_APP_CONV_AI_CUSTOM_STUDY_ENABLED) {
            studiesApiDataArr = [...studiesApiData, ...customStudies];
        } else {
            studiesApiDataArr = studiesApiData;
        }
        const studyArr: string[] = values.filter((obj) => studiesApiDataArr.includes(obj.label)).map((obj) => obj.label);
        if (!interactive) {
            if (forConvAI) {
                dispatch(SelectedStudiesSlice.actions.setStudies(studyArr.splice(studyArr.length - 1)));
            } else {
                dispatch(SelectedStudiesSlice.actions.setStudies(studyArr));
            }
        } else {
            if (!report.isSingleStudyReport) {
                setStudies(studyArr)
            } else {
                setStudies(studyArr.splice(studyArr.length - 1))
            }
        }
    };

    return (
        <div className={(interactive && studies.length === 0) ? classes.studyAutoCompleteContainerForZeroStudies : classes.studyAutoCompleteContainer} style={{ width: width || '100%' }}>
            <Typography sx={{ margin: '4px' }}>{STUDYS_LABEL}</Typography>
            <Autocomplete
                fullWidth
                multiple
                placeholder={(interactive ? studies.length === 0 : selectedStudies.length === 0) ? STUDIES_PACEHOLDER_TEXT : ''}
                helperText={(interactive ? studies.length === 0 : selectedStudies.length === 0) ? STUDIES_DROPDOWN_HELPER_TEXT : ''}
                source={userStudies}
                value={!interactive ? formatData(selectedStudies) : formatData(Array.from(studies))}
                onChange={handleChange}
                data-testid='autocompleteDropdown'
                size="small"
                error={interactive ? studies.length === 0 : selectedStudies.length === 0}
            />
        </div>
    );
}
import Button from 'apollo-react/components/Button';
import Checkbox from 'apollo-react/components/Checkbox';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { addData, patchData } from '../../../Service/AdminService';

export const MenuDialog = (props) => {
    let idVal
    const { onCancel, parentData, dashboardData, type, render, setRender, URL } = props;
    const [data, setData] = useState({
        id: '',
        name: '',
        displayOrder: '',
        description: '',
        dashboardId: '',
        parentId: '',
        config: {
            permissions: {
                edit: false,
                export: false,
                print: false
            }
        }
    })
    const [editData, setEditData] = useState(parentData)
    const [reqBody, setReqBody] = useState({})
    const [isMapToDashboard, setIsMapToDashboard] = useState(type === 'edit' || type === 'edit menu' ? editData.dashboardId ? true : false : false);
    const dispatch = useDispatch()
    const handleSaveClick = (data) => {
        if (type === 'edit') {
            patchData(`${URL}/${editData.id}`, data)
            dispatch(showBanner({
                variant: 'success',
                message: `Successfully updated ${editData.name} menu`
            }))
        } else if (type === 'add') {
            addData(URL, { ...data, parentId: parentData.id, tenantId: parentData.tenantId })
            dispatch(showBanner({
                variant: 'success',
                message: `Successfully added ${data.name} menu`
            }))
        } else if (type === 'add menu') {
            addData(URL, { ...data, parentId: null, tenantId: parentData.tenantId })
            dispatch(showBanner({
                variant: 'success',
                message: `Successfully added ${data.name} menu`
            }))
        } else {
            patchData(`${URL}/${editData.id}`, data)
            dispatch(showBanner({
                variant: 'success',
                message: `Successfully updated ${editData.name} menu`
            }))
        }
        onCancel(false)
        setTimeout(() => { setRender(!render) }, 1000)
    }
    if (type === 'edit' || type === 'edit menu') {
        idVal = editData.id.toString()
    } else if (type === 'add') {
        idVal = parentData.id.toString()
    } else {
        idVal = 'null'
    }
    return (
        <div style={{ minWidth: '550px' }}>
            <Grid container>
                <Grid item md={6}>
                    <TextField
                        label={(type === 'edit' || type === 'edit menu') ? 'id' : 'Parent Id'}
                        style={{ minWidth: 250 }}
                        value={idVal}
                        disabled
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label='Name'
                        placeholder='Menu Name'
                        style={{ minWidth: 250 }}
                        required
                        value={(type === 'edit' || type === 'edit menu') ? editData.name : data.name}
                        onChange={(e) => {
                            if (type === 'edit' || type === 'edit menu') {
                                setEditData({ ...editData, name: e.target.value })
                            } else {
                                setData({ ...data, name: e.target.value })
                            }
                            setReqBody({ ...reqBody, name: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label='Display Order'
                        placeholder='Display Order'
                        style={{ minWidth: 250 }}
                        required
                        value={type === 'edit' || type === 'edit menu' ? editData.displayOrder.toString() : data.displayOrder.toString()}
                        onChange={(e) => {
                            if (type === 'edit' || type === 'edit menu') {
                                setEditData({ ...editData, displayOrder: e.target.value })
                            } else {
                                setData({ ...data, displayOrder: e.target.value })
                            }
                            setReqBody({ ...reqBody, displayOrder: toNumber(e.target.value) });
                        }}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        label='Description'
                        placeholder='Description'
                        style={{ minWidth: 250 }}
                        value={type === 'edit' || type === 'edit menu' ? (editData.description ? editData.description : '') : (data.description ? data.description : '')}
                        onChange={(e) => {
                            if (type === 'edit' || type === 'edit menu') {
                                setEditData({ ...editData, description: e.target.value })
                            } else {
                                setData({ ...data, description: e.target.value })
                            }
                            setReqBody({ ...reqBody, description: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item md={12} style={{ marginTop: '10px' }}>
                    <Checkbox label='Map to dashboard?' checked={isMapToDashboard} onChange={(e, checked) => {
                        setIsMapToDashboard(!isMapToDashboard)
                        if (!checked) {
                            setReqBody({ ...reqBody, dashboardId: null, config: {} })
                        }
                    }} />
                </Grid>
                {isMapToDashboard && (
                    <>
                        <Grid item md={6}>
                            <Select
                                label='Dashboard'
                                value={type === 'edit' || type === 'edit menu' ? editData.dashboardId ? editData.dashboardId.toString() : '' : data.dashboardId ? data.dashboardId.toString() : ''}
                                onChange={(e) => {
                                    if (type === 'edit' || type === 'edit menu') {
                                        setEditData({ ...editData, dashboardId: e.target.value })
                                    } else {
                                        setData({ ...data, dashboardId: e.target.value })
                                    }
                                    setReqBody({ ...reqBody, dashboardId: toNumber(e.target.value) });
                                }}
                                placeholder='Select item...'
                                required
                                style={{ minWidth: 250 }}>
                                {dashboardData.map((dashboard) => {
                                    return <MenuItem value={dashboard.id} key={dashboard.id}>{dashboard.name}</MenuItem>
                                })
                                }
                            </Select>
                        </Grid>
                        <Grid item md={6} style={{ margin: 'auto', paddingTop: '1rem' }}>
                            <Checkbox checked={type === 'edit' ? editData.config.permissions ? editData.config.permissions.print : false : data.config.permissions.print} onChange={(e, checked) => {
                                if (type === 'edit' || type === 'edit menu') {
                                    setEditData({
                                        ...editData, config: {
                                            permissions: {
                                                edit: editData.config.permissions ? editData.config.permissions.edit : false,
                                                print: checked,
                                                export: editData.config.permissions ? editData.config.permissions.export : false
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: editData.config.permissions ? editData.config.permissions.edit : false,
                                                print: checked,
                                                export: editData.config.permissions ? editData.config.permissions.export : false
                                            }
                                        }
                                    });
                                } else {
                                    setData({
                                        ...data, config: {
                                            permissions: {
                                                edit: data.config.permissions ? data.config.permissions.edit : false,
                                                print: checked,
                                                export: data.config.permissions ? data.config.permissions.export : false
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: data.config.permissions ? data.config.permissions.edit : false,
                                                print: checked,
                                                export: data.config.permissions ? data.config.permissions.export : false
                                            }
                                        }
                                    });
                                }
                            }} label='Print' />
                            <Checkbox checked={type === 'edit' || type === 'edit menu' ? editData.config.permissions ? editData.config.permissions.export : false : data.config.permissions.export} onChange={(e, checked) => {
                                if (type === 'edit' || type === 'edit menu') {
                                    setEditData({
                                        ...editData, config: {
                                            permissions: {
                                                edit: editData.config.permissions ? editData.config.permissions.edit : false,
                                                print: editData.config.permissions ? editData.config.permissions.print : false,
                                                export: checked
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: editData.config.permissions ? editData.config.permissions.edit : false,
                                                print: editData.config.permissions ? editData.config.permissions.print : false,
                                                export: checked
                                            }
                                        }
                                    });
                                } else {
                                    setData({
                                        ...data, config: {
                                            permissions: {
                                                edit: data.config.permissions ? data.config.permissions.edit : false,
                                                print: data.config.permissions ? data.config.permissions.print : false,
                                                export: checked
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: data.config.permissions ? data.config.permissions.edit : false,
                                                print: data.config.permissions ? data.config.permissions.print : false,
                                                export: checked
                                            }
                                        }
                                    });
                                }
                            }} label='Export' />
                            <Checkbox checked={type === 'edit' || type === 'edit menu' ? editData.config.permissions ? editData.config.permissions.edit : false : data.config.permissions.edit} onChange={(e, checked) => {
                                if (type === 'edit' || type === 'edit menu') {
                                    setEditData({
                                        ...editData, config: {
                                            permissions: {
                                                edit: checked,
                                                print: editData.config.permissions ? editData.config.permissions.print : false,
                                                export: editData.config.permissions ? editData.config.permissions.export : false
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: checked,
                                                print: editData.config.permissions ? editData.config.permissions.print : false,
                                                export: editData.config.permissions ? editData.config.permissions.export : false
                                            }
                                        }
                                    });
                                } else {
                                    setData({
                                        ...data, config: {
                                            permissions: {
                                                edit: checked,
                                                print: data.config.permissions ? data.config.permissions.print : false,
                                                export: data.config.permissions ? data.config.permissions.export : false
                                            }
                                        }
                                    })
                                    setReqBody({
                                        ...reqBody, config: {
                                            permissions: {
                                                edit: checked,
                                                print: data.config.permissions ? data.config.permissions.print : false,
                                                export: data.config.permissions ? data.config.permissions.export : false
                                            }
                                        }
                                    });
                                }
                            }} label='Edit' />
                        </Grid>
                    </>
                )}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button variant='text' style={{ marginRight: 10 }} onClick={() => {
                    onCancel(false)
                }}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => handleSaveClick(reqBody)}>
                    Save
                </Button>
            </div>
        </div>
    );
};
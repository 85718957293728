import Box from 'apollo-react/components/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useAppStyles } from '../../AppStyles';
import { SelectedStudiesSlice } from '../../Redux/Slice/SelectedStudiesSlice';
import { UserStudiesApi } from '../../Redux/apis/UserStudiesApi';
import { RootState } from '../../Redux/store';
import { ConvAiBody } from './Components/ConvAiBody';
import { ConvAiHeader } from './Components/ConvAiHeader';

export const AiAssistPage = () => {
    const appClasses = useAppStyles();
    const dispatch = useDispatch();
    const { data: studiesApiData } = UserStudiesApi.useFetchUserStudiesQuery({});
    const selectedStudies = useSelector((state: RootState) => {
        const selStudies = state.selectedStudiesSlice.selectedStudies;
        if (!selStudies.length && studiesApiData) {
            dispatch(SelectedStudiesSlice.actions.addStudy(studiesApiData[0]))
            dispatch(SelectedStudiesSlice.actions.setWasSet(true))
        } else if (selStudies.length !== 1) {
            dispatch(SelectedStudiesSlice.actions.setStudies(selStudies.slice(0, 1)))
        }
        return selStudies
    });

    return (
        <Box className={appClasses.pageContainer}>
            <ConvAiHeader />
            {selectedStudies.length === 1 && <ConvAiBody />}
        </Box>
    )
};
import { createSlice } from '@reduxjs/toolkit';

export const SelectedReportSlice = createSlice({
    name: 'selectedReportSlice',
    initialState: {
        selectedReport: {} as any,
        resetTime: Date.now()
    },
    reducers: {
        setSelectedReport(state, { payload }) {
            state.selectedReport = payload;
        },

        resetSelectedReportData(state) {
            // Need to find more professional way to reset the report
            state.resetTime = Date.now();
        }
    }
});

export const selectedReportReducer = SelectedReportSlice.reducer;
export const selectedReportAction = SelectedReportSlice.actions;
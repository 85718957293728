import { HomeLayout } from "../Home/HomeLayout";
import { AnalyzePage } from "./AnalyzePage";

const AnalyzePageRoutes = [
  {
    path: '/analyze',
    component: AnalyzePage,
    layout: HomeLayout,
    exact: true,
    authentication: true,
  },
];

export default AnalyzePageRoutes;
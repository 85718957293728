import { makeStyles } from "@mui/styles";
import { NOTE } from "../../Constants";
import StatusCheck from 'apollo-react-icons/StatusCheck';

const useStyles = makeStyles({
  notification: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem",
    boxSizing: "border-box",
    borderRadius: "4px",
    margin: "1rem 0",
    width: "100%",
    color: "#444444",
    minHeight: "48px",
    fontFamily: "Proxima Nova, Nunito Sans, sans-serif"
  },
  info: {
    backgroundColor: "#ECF3FF",
    border: "1px solid #C7DCFE",
    borderLeft: "4px solid #0768FD"
  },
  convAIInfo: {
    backgroundColor: "#E5F8E8",
    border: "1px solid #B3ECBC",
    borderLeft: "4px solid #00C221"
  },
  infoIcon: {
    "&:before": {
      verticalAlign: "-10%",
      margin: "0.5rem",
      content: `url(/icon/INFO_ICON_17x17.svg)`
    }
  },
  statusCheckInfoIcon: {
    verticalAlign: "-30%",
    margin: "0.5rem",
  },
  fw600: { fontWeight: "600" }
});

export const InfoMessage = (props) => {
  const { message, disable, forConvAI } = props;
  const classes = useStyles();
  if (disable) {
    return (
      <div className={`${classes.notification} ${forConvAI ? classes.convAIInfo : classes.info}`}>
        <span className="wrapper">
          {!forConvAI ? <span className={`icon ${classes.fw600} ${classes.infoIcon}`}>
            {NOTE}
          </span> :
            <span className={classes.statusCheckInfoIcon}>
              <StatusCheck htmlColor={'#00C221'} />
            </span>}
          <span className="message">{message}</span>
        </span>
      </div>
    )
  }
  else {
    return null
  }
};
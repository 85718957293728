import Footer from 'apollo-react/components/Footer';

export const HomeFooter = () => {
    return (
        <div>
            <Footer
                buttonProps={[
                    {
                        label: 'Terms of Use',
                        href: 'https://www.iqvia.com/about-us/terms-of-use',
                        target: '_blank',
                    },
                    {
                        label: 'Privacy Policy',
                        href: 'https://www.iqvia.com/about-us/privacy/privacy-policy',
                        target: '_blank',
                    },
                ]}
            />
        </div>
    )
}

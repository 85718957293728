import Modal from 'apollo-react/components/Modal';
import React from 'react';

const CustomModal = (props) => {
  return (
    <Modal
      open={props.display}
      onClose={props.handleClose}
      title={props.title}
      subtitle={props.subtitle}
      message={props.message}
      hideButtons={props?.hideButtons}
      buttonProps={props.buttonProps}
      id={props.type}
      variant={props.variant}
      data-testid='confirm-modal'
    >
      {props.body}
    </Modal>
  );
};

export default CustomModal;

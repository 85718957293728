import Cookies from 'js-cookie';
import { ACCESS_TOKEN, API_KEY, USER_TOKEN } from '../Constants';
import { isStrTrue } from '../Utils';
import { getAccessToken } from './AuthService';
import { encrypt } from './CryptoService';

const commonHeaders = () => {
  return {
    'tenant': checkCookieAvailble('tenant'),
    'access-token': checkCookieAvailble(ACCESS_TOKEN),
    'token-type': 'user',
    'sys-name': 'CA',
    'api-key': getApiKey(),
    'Authorization': checkCookieAvailble(USER_TOKEN)
  }
}

export const checkCookieAvailble = (value: string) => {
  if (value === USER_TOKEN) {
    return Cookies.get(value) ? `Bearer ${Cookies.get(value)}` : null;
  } else {
    return Cookies.get(value) ? Cookies.get(value) : null;
  }
}

export const getCoreApiHeaders = async () => {
  const headers = commonHeaders();
  return headers;
}

export const getCaApiHeaders = () => {
  const pbiAuthType = window.sessionStorage.getItem('pbiAuthType');
  const headers = commonHeaders();

  headers['sel-studies'] = window.sessionStorage.getItem('sel-studies');
  if (pbiAuthType && pbiAuthType === 'User') {
    headers['pbi-accesstoken'] = getAccessToken().then(t => t);
  }
  return headers;
}

export const getApiKey = () => {
  return isStrTrue(process.env.REACT_APP_FF_CREATE_APIKEY) ? encrypt(process.env.REACT_APP_CDAS_CA_VAULT_API_KEY ?? '', process.env.REACT_APP_CDAS_CA_VAULT_ENCRYPTION_KEY ?? '') : checkCookieAvailble(API_KEY);
}
import { LOGOUT_PATH } from '../../Constants';
import Logout from './Logout';

const logoutRoutes = [
  {
    path: LOGOUT_PATH,
    component: Logout,
    layout: Logout,
    exact: true,
    authentication: false,
  },
];

export default logoutRoutes;

import { makeStyles } from '@mui/styles';
import Box from 'apollo-react/components/Box';
import { useFormik } from 'formik';
import * as powerbi from 'powerbi-client';
import * as models from 'powerbi-models';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  CANCEL_LABEL,
  COMMENT,
  COMP_PBICONFIG,
  DEFAULT,
  DUPLICATE_REPORT_NAME,
  ERROR,
  INVALID_REPORT_NAME,
  MAX_CHAR_ALLOWED,
  NAME_REQUIRED,
  NEW_CUSTOM_DASHBOARD,
  SAVE_LABEL,
  SELF_SERVICE_REPORT,
} from '../../Constants';
import { AuditAction, AuditCategory, AuditSubCategory } from '../../Constants/Enums';
import { Routes, TARGET_WORKSPACE } from '../../Constants/PathConstants';
import { HomeContext } from '../../Pages/Home/HomeLayout';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { reportConfig } from '../../Redux/Slice/ReportSlice';
import { SelectedReportSlice } from '../../Redux/Slice/SelectedReportSlice';
import { RootState } from '../../Redux/store';
import logUserAction from '../../Service/AuditLogService';
import { createWorkSpace } from '../../Service/CreateWorkspaceService';
import { isStrTrue } from '../../Utils';
import { getApiPath } from '../../Utils/PathUtils';
import { CommentBox } from '../Comment/CommentBox';
import BannerMessage from '../ModelDialog/BannerMessage';
import CustomModal from '../ModelDialog/CustomModal';
import ReportToolBar from './ReportToolBar';
import SaveAsReport from './SaveAsReport';

// ensure Power BI JavaScript API has loaded
require('powerbi-models');
require('powerbi-client');

const useStyles = (menubar: any, isEmbedPage?: any) => {
  const getHeight = () => {
    if (isEmbedPage) {
      return '51px';
    } else if (menubar !== undefined) {
      return '106px';
    } else {
      return '67px';
    }
  };
  return makeStyles({
    EmbedContainer: {
      height: `calc(100vh - ${getHeight()}) !important`,
      width: '99.5%',
    },
  });
};

const PBIconfig = (props: any) => {
  const embedContainer = useRef(null as any);
  const {
    configReport,
    createConfigForCreate,
    isEmbedPage,
    permissions,
    authType,
    signedRequest,
    isCustomReport,
  } = props;

  const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
  const selectedReport = useSelector((state: RootState) => state.selectedReportSlice.selectedReport);
  const selfServiceReports = useSelector((state: RootState) => state.selfServiceReportSlice.selfServiceReports);

  const getFilterValues = (item: any) => {
    if (item.name === 'study') {
      return selectedStudies;
    }
  }

  const [embedConfigData, setEmbedConfig] = useState(createConfigForCreate);
  const classes = useStyles(configReport, isEmbedPage)();
  const [embeddedReport, setEmbeddedReport] = useState<powerbi.Report | null>(null);
  const [editMode, setEditMode] = React.useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [openSaveAsDialog, setOpenSaveAsDialog] = React.useState(false);
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
  const validationSchema = yup.object({
    reportName: yup.string().test('is reportName valid', INVALID_REPORT_NAME, (value, ctx) => {

      if (!value || value.trim().length === 0) {
        return ctx.createError({ message: NAME_REQUIRED });
      } else if (value?.length > 50) {
        return ctx.createError({ message: MAX_CHAR_ALLOWED });
      } else if (value.trim() === NEW_CUSTOM_DASHBOARD) {
        return ctx.createError({ message: INVALID_REPORT_NAME });
      } else if (selfServiceReports.some(report => report.name?.toLowerCase() === value.trim()?.toLowerCase())) {
        return ctx.createError({ message: DUPLICATE_REPORT_NAME });
      } else {
        return true;
      }
    }),
  });
  const dispatch = useDispatch();
  const homeContextData: any = useContext(HomeContext);
  const {
    configData,
    isNewReportAdded,
    setIsNewReportAdded,
    targetWorkspaceId,
    setTargetWorkspaceId,
  } = homeContextData;

  const editPermission = isCustomReport ? models.Permissions.All : models.Permissions.Copy;
  let reportFilters: any;

  if (selectedReport?.parentMenuName === SELF_SERVICE_REPORT && configReport?.reportFilters.every((item: any) => item.name !== 'study')) {
    // Add study filter for self-service report
    const studyFilter = {
      name: "study",
      table: "Demographics",
      column: "Study Identifier"
    }
    configReport?.reportFilters.push(studyFilter);
  }

  const isFilterAvailable = configReport?.reportFilters !== undefined;
  if (isFilterAvailable) {
    reportFilters = configReport?.reportFilters.map((item: any) => {
      const filterValues = item.values ? item.values : getFilterValues(item);
      return {
        $schema: 'https://powerbi.com/product/schema#basic',
        target: {
          table: item.table,
          column: item.column,
        },
        operator: 'In',
        values: filterValues,
        displaySettings: { isHiddenInViewMode: true },
      };
    });
  }

  const getMenuCommands = () => {
    let commands = {};
    if (process.env.REACT_APP_FF_PBI_CHART_EXPORT !== 'true') {
      commands = {
        ...commands,
        exportData: {
          displayOption: models.CommandDisplayOption.Hidden,
        },
      };
    }
    return commands;
  };

  const embedConfigForView = {
    type: configReport.type,
    id: configReport.id,
    embedUrl: configReport.embedUrl,
    accessToken: configReport.token,
    tokenType: authType === 'SP' ? models.TokenType.Embed : models.TokenType.Aad,
    permissions: permissions?.edit ? editPermission : models.Permissions.Read,
    filters: isFilterAvailable ? reportFilters : [],
    viewMode: models.ViewMode.View,
    settings: {
      filterPaneEnabled: true,
      bars: {
        actionBar: {
          visible: false,
        },
      },
      background: models.BackgroundType.Transparent,
      useCustomSaveAsDialog: true,
      commands: [getMenuCommands()],
      extensions: isStrTrue(process.env.REACT_APP_FF_COMMENT_BOX) ? [
        {
          command: {
            name: COMMENT,
            title: COMMENT,
            extend: {
              visualOptionsMenu: {
                title: COMMENT,
              }
            }
          }
        }
      ] : []
    },
  };

  const formik = useFormik({
    initialValues: {
      reportName: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const URL: any = getApiPath(TARGET_WORKSPACE, Routes.PBI)
      let targetWorkspace: string =
        targetWorkspaceId !== '' ? targetWorkspaceId : configData?.targetWorkspace?.id;
      if (targetWorkspace === undefined) {
        const response = await createWorkSpace(URL);
        if (!response?.error) {
          targetWorkspace = response?.id;
        } else {
          dispatch(
            showBanner({
              variant: ERROR,
              message: response.message,
            }),
          );
        }
      }
      if (targetWorkspace !== undefined) {
        embeddedReport?.saveAs({
          name: values.reportName,
          targetWorkspaceId: targetWorkspace,
        });
      }
      embeddedReport?.on('error', function (event) {
        console.log(event, 'error');
      });
      embeddedReport?.off("saved");
      embeddedReport?.on('saved', async function (event: any) {
        let correlationId = await embeddedReport?.getCorrelationId();
        await logUserAction(
          AuditCategory.REPORT,
          AuditSubCategory.SELFSERVICE,
          AuditAction.CREATE,
          {
            reportId: event?.detail.reportObjectId,
            workspaceId: targetWorkspace,
            reportName: values.reportName,
            datasetId: configReport?.datasetId,
            sourceReportId: selectedReport?.reportId,
            sourceWorkspaceId: selectedReport?.workspaceId,
            sourceReportName: selectedReport?.name,
            correlationId: correlationId
          }
        );
        dispatch(
          showBanner({
            variant: 'success',
            message: `'${values.reportName}' was saved to '${SELF_SERVICE_REPORT}s'`,
            curComponent: COMP_PBICONFIG,
          })
        );
        dispatch(
          SelectedReportSlice.actions.setSelectedReport({
            name: `${values.reportName}`,
            parentMenuName: SELF_SERVICE_REPORT,
            permissions: selectedReport?.permissions,
            clientName: selectedReport?.clientName,
            reportId: event?.detail.reportObjectId,
            workspaceId: targetWorkspace,
            isSingleStudyReport: false,
          })
        );
        setTargetWorkspaceId(targetWorkspace);
        setIsNewReportAdded(!isNewReportAdded);
      });
      setOpenSaveAsDialog(false);
      resetForm({ values: { reportName: '' } });
    },
  });

  const saveReport = () => {
    embeddedReport?.save();
    embeddedReport?.off('saved');
    embeddedReport?.on('saved', async function (event) {
      let correlationId = await embeddedReport?.getCorrelationId();
      await logUserAction(AuditCategory.REPORT, AuditSubCategory.SELFSERVICE, AuditAction.UPDATE, {
        reportId: selectedReport?.reportId,
        workspaceId: selectedReport?.workspaceId,
        reportName: selectedReport?.name,
        datasetId: configReport?.datasetId,
        correlationId: correlationId,
      });
      dispatch(
        showBanner({
          variant: 'success',
          message: `${selectedReport?.name} successfully saved`,
          curComponent: COMP_PBICONFIG
        })
      );
    });
    setOpenSaveDialog(false);
  };

  const handleSaveDialogClose = () => {
    setOpenSaveDialog(false);
  };

  const handleClose = () => {
    formik.resetForm({ values: { reportName: '' } });
    setOpenSaveAsDialog(false);
  };

  const onToggleEditMode = useCallback(() => {
    const mode = isCustomReport ? editMode : !editMode;
    embeddedReport?.switchMode(mode ? models.ViewMode.Edit : models.ViewMode.View);
    setEditMode(!editMode);
  }, [embeddedReport, editMode, isCustomReport]);

  useEffect(() => {
    if (embedConfigData.embedUrl !== configReport.embedUrl) {
      if (configReport.type === 'create') {
        setEmbedConfig(createConfigForCreate);
      } else {
        setEmbedConfig(embedConfigForView);
      }
    }
    window.powerbi.reset(embedContainer.current);
    let report: any = window.powerbi.embed(embedContainer.current, embedConfigForView);
    let canUserEdit = !isEmbedPage && permissions?.edit;
    setEmbeddedReport(report);
    dispatch(reportConfig(report));
    setCanEdit(canUserEdit);
    setEditMode(canUserEdit);

    report.on('loaded', async (event) => {
      const defaultMode = isCustomReport ? !editMode : editMode;
      report?.switchMode(defaultMode ? models.ViewMode.Edit : models.ViewMode.View);
    });

    // report.on('error', (event) => {
    //   console.log('ERROR in embedded report', event);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createConfigForCreate, embedContainer.current, selectedStudies]);

  return (
    <React.Fragment>
      <ReportToolBar
        embeddedReport={embeddedReport}
        isEmbedPage={isEmbedPage}
        signedRequest={signedRequest}
        canEdit={canEdit}
        configReport={configReport}
        editMode={isCustomReport ? !editMode : editMode}
        isCustomReport={isCustomReport}
        onToggleEditMode={onToggleEditMode}
        setOpenSaveAsDialog={setOpenSaveAsDialog}
        setOpenSaveDialog={setOpenSaveDialog}
      />
      {isStrTrue(process.env.REACT_APP_FF_COMMENT_BOX) ? <div style={{ display: 'flex' }}>
        <div style={{ width: '80%' }}>
          <Box className={classes.EmbedContainer} ref={embedContainer} id='embed-container' />
        </div>
        <div style={{ width: '20%' }}>
          <CommentBox embeddedReport={embeddedReport} />
        </div>
      </div> : <Box className={classes.EmbedContainer} ref={embedContainer} id='embed-container' />}
      <CustomModal
        display={openSaveDialog}
        handleClose={handleSaveDialogClose}
        title='Do you want to Save report?'
        type={DEFAULT}
        variant={DEFAULT}
        buttonProps={[
          { label: CANCEL_LABEL, onClick: handleSaveDialogClose },
          { label: SAVE_LABEL, onClick: saveReport },
        ]}
      />
      <BannerMessage top_margin={isEmbedPage ? true : false} component={COMP_PBICONFIG} />
      <CustomModal
        saveAsClicked={openSaveAsDialog}
        display={openSaveAsDialog}
        handleClose={handleClose}
        title={'Save to Self Service Reports'}
        type={DEFAULT}
        variant={DEFAULT}
        buttonProps={[
          { label: CANCEL_LABEL, onClick: handleClose },
          { label: SAVE_LABEL, onClick: formik.handleSubmit },
        ]}
        body={<SaveAsReport formik={formik} />}
      />
    </React.Fragment>
  );
};

export default PBIconfig;

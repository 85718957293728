import axios from 'axios';
import { Dispatch } from 'react';
import { GENERAL_ERROR_MSG, SELF_SERVICE_REPORT } from '../Constants';
import { REPORTS_BY_WORKSPACE_ID_AND_REPORTS_LIMIT, Routes } from '../Constants/PathConstants';
import { selfServiceReportAction } from '../Redux/Slice/SelfServiceReportSlice';
import { getApiPath } from '../Utils/PathUtils';

export const getSelfServiceMenu = async (
  dispatch: Dispatch<any>,
  workspaceId: string,
  limit?: number | any
) => {
  const reportsLimit = limit ? `reports?limit=${limit}` : 'reports';
  const URL = getApiPath(REPORTS_BY_WORKSPACE_ID_AND_REPORTS_LIMIT, Routes.PBI, { 'workspaceId': workspaceId, 'reportsLimit': reportsLimit })
  return axios
    .get(URL)
    .then((response) => {
      dispatch(selfServiceReportAction.setSelfServiceReports(response.data));
      return response.data.map((item: any) => {
        return { ...item, parentMenuName: SELF_SERVICE_REPORT }
      });
    })
    .catch((error) => {
      return {
        error: true,
        message:
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : GENERAL_ERROR_MSG,
      };
    });
}

import Print from 'apollo-react-icons/Print';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONFIG_PARAM,
  NEW_CUSTOM_DASHBOARD,
  REPORT_ID_PARAM,
  SELF_SERVICE_REPORT,
  WORKSPACE_ID_PARAM
} from '../../Constants';
import { AuditAction, AuditCategory, AuditSubCategory } from '../../Constants/Enums';
import { RootState } from '../../Redux/store';
import logUserAction from '../../Service/AuditLogService';
import { resetCurrentReportData } from '../../Service/ReportService';
import CustomModal from '../ModelDialog/CustomModal';
import { InprogressMessage } from '../ModelDialog/InprogessMessage';
import ExportReport from './ExportReport';

const ReportToolBar = (props: {
  embeddedReport: any;
  isEmbedPage: boolean;
  canEdit: boolean;
  editMode: boolean;
  isCustomReport: boolean;
  configReport: any;
  signedRequest: string;
  onToggleEditMode: () => void;
  setOpenSaveAsDialog: Dispatch<SetStateAction<boolean>>;
  setOpenSaveDialog: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    canEdit,
    isEmbedPage,
    editMode,
    isCustomReport,
    onToggleEditMode,
    embeddedReport,
    configReport,
    signedRequest,
    setOpenSaveDialog,
    setOpenSaveAsDialog,
  } = props;

  const dispatch = useDispatch();

  const printEmbeddedReport = async () => {
    embeddedReport.print();

    // Audit Print action
    await logUserAction(AuditCategory.REPORT, AuditSubCategory.PRINT_REPORT, AuditAction.PRINT, {
      reportId: selectedReport?.reportId,
      workspaceId: selectedReport?.workspaceId,
      reportName: selectedReport?.name,
      datasetId: configReport?.datasetId,
      correlationId: embeddedReport.correlationId,
    });
  };
  const [isCancel, setIsCancel] = useState(false);
  const { type, reportName, open, embeddedReportName } = useSelector(
    (state: RootState) => state.InProgressMsg
  );
  const selectedReport = useSelector((state: RootState) => state.selectedReportSlice.selectedReport)
  const permissions = selectedReport?.permissions;
  const embedUrl = configReport?.embedUrl;
  const reportIdIndex = embedUrl?.indexOf(REPORT_ID_PARAM) + REPORT_ID_PARAM.length;
  const workspaceIdIndex = embedUrl?.indexOf(WORKSPACE_ID_PARAM);
  const reportId = embedUrl?.slice(reportIdIndex, workspaceIdIndex);
  const datasetId = configReport?.datasetId;
  const workspaceId = embedUrl?.slice(
    workspaceIdIndex + WORKSPACE_ID_PARAM.length,
    embedUrl.indexOf(CONFIG_PARAM)
  );

  const isCreateNewReport =
    selectedReport?.parentMenuName === SELF_SERVICE_REPORT &&
    selectedReport.name === NEW_CUSTOM_DASHBOARD;
  const isPrintEnabled = isEmbedPage ? isEmbedPage : permissions?.print;
  const isExportEnabled = isEmbedPage ? isEmbedPage : !isCreateNewReport && permissions?.export;
  const handleClose = () => {
    setIsCancel(false);
  };
  const handleEditMode = async () => {
    const isSaved = await embeddedReport?.isSaved();
    onToggleEditMode();
    if (!isSaved) {
      await embeddedReport?.reload();
    }
    setIsCancel(false);
  };
  return (
    <React.Fragment>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.25rem',
          display: 'flex',
          marginRight: '0.5rem',
          justifyContent: 'space-between',
        }}>
        {open && (
          <InprogressMessage
            type={type}
            reportName={isEmbedPage ? embeddedReportName : reportName}
            isEmbedPage={isEmbedPage}
          />
        )}
        <div style={{ display: 'flex' }}>
          {selectedReport?.parentMenuName === SELF_SERVICE_REPORT && (
            <Typography
              variant='title2'
              style={{
                fontSize: '20px',
                marginLeft: '0.5rem',
                marginTop: '0.25rem',
              }}>
              {selectedReport?.name}
            </Typography>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          {selectedReport?.parentMenuName !== SELF_SERVICE_REPORT &&
            <Button
              variant='secondary'
              display='none'
              size='small'
              style={{ marginLeft: '1rem', fontFamily: 'Proxima Nova' }}
              onClick={() => resetCurrentReportData(dispatch)}>
              Reset
            </Button>
          }
          {canEdit && editMode && (
            <React.Fragment>
              {(
                <Button
                  size='small'
                  style={{ marginLeft: '1rem', fontFamily: 'Proxima Nova' }}
                  onClick={() => setIsCancel(true)}>
                  Cancel
                </Button>
              )}
              {isCustomReport && (
                <Button
                  variant='primary'
                  size='small'
                  style={{ marginLeft: '1rem', fontFamily: 'Proxima Nova' }}
                  onClick={() => setOpenSaveDialog(true)}>
                  Save
                </Button>
              )}
              {(
                <Button
                  variant={isCustomReport ? 'secondary' : 'primary'}
                  size='small'
                  style={{ marginLeft: '1rem', fontFamily: 'Proxima Nova' }}
                  onClick={() => setOpenSaveAsDialog(true)}>
                  {!isCustomReport ? 'Save' : 'Save as'}
                </Button>
              )}
            </React.Fragment>
          )}
          {canEdit && !editMode && (
            <React.Fragment>
              <Button
                variant='primary'
                size='small'
                style={{ marginLeft: '1rem' }}
                onClick={onToggleEditMode}>
                Edit
              </Button>
            </React.Fragment>
          )}
          {isExportEnabled && (
            <ExportReport
              isEmbedPage={isEmbedPage}
              workspaceId={workspaceId}
              reportId={reportId}
              datasetId={datasetId}
              signedRequest={signedRequest}
              selectedReport={selectedReport}
            />
          )}
          {isPrintEnabled && (
            <Print
              onClick={printEmbeddedReport}
              style={{
                marginLeft: '1rem',
                marginTop: '0.25rem',
                color: '#0557d5',
                cursor: 'pointer',
              }}
              data-testid='print-icon'
            />
          )}
          {/* <Share
            style={{
              marginLeft: '1rem',
              marginTop: '0.25rem',
              color: '#0557d5',
            }}
            data-testid='share-icon'
          />
          <Bookmark
            style={{
              marginLeft: '1rem',
              marginTop: '0.25rem',
              color: '#0557d5',
            }}
            data-testid='bookmark-icon'
          /> */}
        </div>
      </div>
      <div>
        <CustomModal
          display={isCancel}
          handleClose={handleClose}
          title={'Lose your work?'}
          message='All unsaved changes will be lost.'
          type='warning'
          variant='warning'
          buttonProps={[
            { label: 'Keep editing', onClick: handleClose },
            { label: 'Leave without saving', onClick: handleEditMode },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default ReportToolBar;
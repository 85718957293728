import HelpIcon from '@mui/icons-material/Help';
import { createTheme } from '@mui/material';
import Link from 'apollo-react/components/Link';
import NavigationBar from 'apollo-react/components/NavigationBar';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import ApolloThemeProvider from 'apollo-react/utils/ApolloThemeProvider';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { HOME_PATH, LOGOUT_PATH } from '../../Constants';
import { MainMenuPermissionNames } from '../../Constants/Enums';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { RootState } from '../../Redux/store';
import { Logout } from '../../Service/LogoutService';
import DynamicAppSwitcher from '../AppSwitcher/DynamicAppSwitcher';
import BannerMessage from '../ModelDialog/BannerMessage';
import { getLoginDate, getUserName } from './utils';

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const { quickHelp } = useSelector((state: RootState) => state.InitialConfig);
  const mainMenuPermissions = useSelector((state: RootState) => state.mainMenuPermissionsSlice.mainMenuPermissions);

  const gotoLoginPage = async () => {
    let path = LOGOUT_PATH;
    const response: any = await Logout();
    if (response === true) {
      history.push({
        pathname: path,
        state: {
          logout: true,
        },
      });
    } else {
      dispatch(
        showBanner({
          variant: 'error',
          message: response.message,
        })
      );
      setError(response.error);
    }
  };

  const menuItems = [
    {
      text: 'Home',
      pathname: '/home',
      active: location?.pathname?.startsWith('/home'),
      hasAccess: true,
    },
    {
      text: 'Analyze',
      pathname: '/analyze',
      active: location?.pathname?.startsWith('/analyze'),
      hasAccess: true,
    },
    {
      text: 'AI Assistant',
      pathname: '/ai-assistant',
      active: location?.pathname?.startsWith('/ai-assistant'),
      hasAccess: mainMenuPermissions ? mainMenuPermissions[MainMenuPermissionNames.AI_ASSISTANT] : false,
    },
    {
      text: 'Admin',
      pathname: '/admin',
      active: location?.pathname?.startsWith('/admin'),
      hasAccess: mainMenuPermissions ? mainMenuPermissions[MainMenuPermissionNames.ADMIN] : false,
    },

  ];

  const profileMenuProps = {
    name: getUserName(),
    title: Cookies.get('user.email') ? Cookies.get('user.email') : 'User Info',
    email: Cookies.get('user.last_login_ts') ? `Last Login : ${getLoginDate()}` : '',
    logoutText: 'Log out',
    menuItems: [],
    onClick: () => gotoLoginPage(),
  };

  const theme = createTheme({
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
    }
  })

  return (
    <React.Fragment>
      <NavigationBar
        LogoComponent={() => (
          <div style={{ display: 'flex' }}>
            <DynamicAppSwitcher />
            <Link to={HOME_PATH} style={{ textDecoration: 'none', borderBottom: 0 }}>
              <ApolloThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: 'white',
                    lineHeight: '56px',
                    marginRight: 24,
                    cursor: 'pointer',
                    zIndex: 2,
                    whiteSpace: 'nowrap',
                  }}
                  data-testid='home'>
                  IQVIA™{'  '}
                  <span style={{ fontWeight: 600 }}>Clinical Analytics</span>
                </Typography>
              </ApolloThemeProvider>
            </Link>
          </div>
        )}
        waves
        menuItems={menuItems.filter((item) => item.hasAccess)}
        profileMenuProps={profileMenuProps}
        maxWidth={'100%'}
        onClick={({ pathname }) => history.push(pathname)}
        otherButtons={
          quickHelp?.enabled && (
            <Tooltip variant='light' title='Help' placement='bottom'>
              <HelpIcon
                onClick={() => window.open(quickHelp.link, '_blank')}
                data-testid='help-icon'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )
        }
        data-testid='navigation-bar'
      />
      {error && <BannerMessage />}
    </React.Fragment>
  );
};
export default Header;
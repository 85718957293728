import TextField from 'apollo-react/components/TextField';
import { MAX_CHAR_ALLOWED } from '../../Constants';

const SaveAsReport = (props) => {
  const { formik } = props;
  return (
    <TextField
      id='report-name'
      name='reportName'
      data-testid='reportName-input'
      label='Report Name'
      placeholder='Please enter report name'
      fullWidth
      required
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.reportName && Boolean(formik.errors.reportName)}
      helperText={
        formik.errors.reportName !== undefined
          ? formik.touched.reportName && formik.errors.reportName
          : MAX_CHAR_ALLOWED
      }
      value={formik.values.reportName}
    />
  );
};
export default SaveAsReport;

import TextField from 'apollo-react/components/TextField';

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type='number'
      style={{ width: 74 }}
      margin='none'
      size='small'
    />
  );
};

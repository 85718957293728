import { useIsAuthenticated } from '@azure/msal-react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import BannerMessage from '../../components/ModelDialog/BannerMessage';
import Report from '../../components/PowerBi/Report';
import { AUTH_PARAM, ERROR, JWT_PARAM } from '../../Constants';
import { showBanner } from '../../Redux/Slice/BannerSlice';
import { setEmbeddedReportName } from '../../Redux/Slice/InProgressMessageSlice';
import { authenticatePBIUser, getAccessToken } from '../../Service/AuthService';

const Embed = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthFailure, setIsAuthFailure] = useState(false);
  const [isAuthCompleted, setIsAuthCompleted] = useState(false);

  const dispatch = useDispatch();

  const tokenIndex = location.search.indexOf(JWT_PARAM) + JWT_PARAM.length;
  const authIndex = location.search.indexOf(AUTH_PARAM) + AUTH_PARAM.length;
  const token = location.search?.includes(JWT_PARAM) && location.search?.slice(tokenIndex);
  const decodedToken: any = jwt_decode(token);
  const email = decodedToken?.context.user.email;
  const reportName = decodedToken?.context?.environment?.parameters.dashboardName;
  const authType =
    location.search?.includes(AUTH_PARAM) &&
    location.search?.slice(authIndex, location.search.indexOf(JWT_PARAM));

  dispatch(setEmbeddedReportName({ reportName }));
  const isPBIUserAuthenticated = useIsAuthenticated();

  if (authType === 'User') {
    axios.interceptors.request.use(
      async (request: any) => {
        request.headers["pbi-accesstoken"] = await getAccessToken()
        return request;
      },
      (error) => {
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      }
    );
  }

  useEffect(() => {
    (async () => {
      if (authType === 'User' && !isPBIUserAuthenticated) {
        if (!isAuthFailure) {
          authenticatePBIUser(setIsAuthFailure, email);
        } else {
          dispatch(
            showBanner({
              variant: ERROR,
              message: 'Error while authenticating powerbi user',
            })
          );
        }
      } else {
        setIsAuthCompleted(true);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthFailure, isPBIUserAuthenticated, authType, email]);

  return (
    <React.Fragment>
      {!isLoading && isAuthCompleted && <Report isEmbed jwtToken={token} authType={authType} />}
      {isAuthFailure && <BannerMessage top_margin={true} />}
    </React.Fragment>
  );
};

export default Embed;

import Banner from 'apollo-react/components/Banner';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeBanner } from '../../Redux/Slice/BannerSlice';
import { RootState } from '../../Redux/store';

const BannerMessage = (props: {
  top_margin?: boolean | any;
  component?: string | any;
}) => {
  const dispatch = useDispatch();
  const { variant, message, open, curComponent } = useSelector((state: RootState) => state.Banner);
  const component = props?.component ? props.component : '';

  const handleClose = () => {
    dispatch(closeBanner());
  };

  const snackBarPos = {
    position: 'fixed',
    zIndex: '1001',
    top: props?.top_margin ? '0px' : '56px',
    minHeight: 'auto',
    width: 'auto',
  };

  useEffect(() => {
    let timeout;
    if (open && variant !== 'error') {
      timeout = setTimeout(() => dispatch(closeBanner()), 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open, variant, dispatch]);

  return (
    <React.Fragment>
      <Banner
        variant={variant}
        open={open && component === curComponent}
        onClose={handleClose}
        message={message}
        style={snackBarPos}
        data-testid='banner-message'
      />
    </React.Fragment>
  );
};

export default BannerMessage;

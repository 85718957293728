import { createSlice } from '@reduxjs/toolkit';

export const EmbedTokenSlice = createSlice({
    name: 'embedTokenSlice',
    initialState: {
        embedToken: '' as any
    },
    reducers: {
        setEmbedToken(state, { payload }) {
            state.embedToken = payload;
        }
    }
});

export const embedTokenReducer = EmbedTokenSlice.reducer;
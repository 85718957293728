import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import { neutral7, neutral8 } from 'apollo-react/colors';
import Grid from 'apollo-react/components/Grid';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import React, { useState } from 'react';

const subMenuItemStyles = {
  headerCell: {
    color: neutral8,
    alignItems: 'center',
    display: 'flex',
    '& > p': { fontSize: '14px', fontWeight: 600 },
  },
  cell: {
    color: neutral7,
    alignItems: 'center',
    display: 'flex',
    '& > p': { fontSize: '14px', fontWeight: 400 },
  },
};

const ActionCellForSubMenu = ({ row, menu }) => {
  const { setEditSubMenu, setAddSubMenu, setIsDelete, setCurrentMenu } = row;
  const handleEdit = () => {
    setCurrentMenu(menu)
    setEditSubMenu(true);
  };

  const handleAddSubMenu = () => {
    setCurrentMenu(menu)
    setAddSubMenu(true)
  }

  const handleDelete = () => {
    setCurrentMenu(menu)
    setIsDelete(true)
  };
  const menuItems = [
    {
      text: 'Edit',
      onClick: handleEdit,
    },
    {
      text: 'Add Submenu',
      onClick: () => handleAddSubMenu(),
    },
    {
      text: 'Delete',
      onClick: handleDelete,
    },
  ];

  return (
    <Tooltip title='Actions' disableFocusListener>
      <IconMenuButton id='actions' menuItems={menuItems} size='small'>
        <EllipsisVerticalIcon />
      </IconMenuButton>
    </Tooltip>
  );
};
const ActionCellForSubMenuMemo = React.memo(ActionCellForSubMenu);

const SubmenuItems = (props) => {
  const { submenu, ...row } = props;
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = makeStyles(subMenuItemStyles)();
  return (
    <Grid container style={{ backgroundColor: '#f8f9fb' }}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 2%',
          paddingTop: 10
        }}>
        <Grid item xs={3} className={classes.headerCell}>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'ID'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Name'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Dashboard Id'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Display Order'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Print'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Export'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Edit'}</Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      {submenu.map((menu) => {
        return (
          <Grid
            item
            xs={12}
            key={menu.id}
            style={{ paddingBottom: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '5px 1% 5px 2%',
              }}>
              <Grid item xs={3} className={classes.cell}>
                {menu.submenu ? <IconButton onClick={() => setIsExpanded(!isExpanded)} >
                  {isExpanded ? <ChevronDown /> : <ChevronRight />}
                </IconButton> : null}
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.id}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.name}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.dashboardId}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.displayOrder}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.print ? 'true' : 'false'}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.export ? 'true' : 'false'}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography>{menu.edit ? 'true' : 'false'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.cell}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ActionCellForSubMenuMemo row={row} menu={menu} />
              </Grid>
            </Grid>
            {isExpanded ? (menu.submenu && <SubmenuItems submenu={menu.submenu} {...menu} />) : null}
          </Grid>
        )
      })}
    </Grid>
  );
};

export default SubmenuItems;

import { SpecialChar } from "../Constants/Enums";

export const ConvAIUtils = {

    getEnhancedFilePath(filePath: string) {
        const modifiedFilePath = filePath?.replace(/\\/g, SpecialChar.SLASH);
        return encodeURIComponent(modifiedFilePath);
    },

    getFileNameFromPath(filePath: string) {
        const lastSlashIdx = filePath?.lastIndexOf(SpecialChar.SLASH);
        return lastSlashIdx !== -1 ? filePath.substring(lastSlashIdx + 1) : filePath;
    }
}
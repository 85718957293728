import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialConfig } from "../../Service/UserConfigService";

export const initialConfig = createAsyncThunk(
    "initialConfig",
    async () => await getInitialConfig()
);

export const InitialConfigSlice = createSlice({
    name: "initialConfig",
    initialState: {
        pbiAuthType: '',
        quickHelp: {
            link: '',
            enabled: false
        }
    },
    reducers: {
        setPbiAuthType(state, { payload }) {
            state.pbiAuthType = payload;
            window.sessionStorage.setItem('pbiAuthType', state.pbiAuthType);
        },
        setQuickHelp(state, { payload }) {
            state.quickHelp = payload;
        },
    },
});
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IntegerFilter } from '../../../components/Filters/IntegerFilter';
import { TextFieldFilter } from '../../../components/Filters/TextFieldFilter';
import CustomModal from '../../../components/ModelDialog/CustomModal';
import { CANCEL_LABEL, POWER_BI } from '../../../Constants';
import { DASHBOARDS, GET_TENANT_BI_CLIENTS, Routes } from '../../../Constants/PathConstants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { getData } from '../../../Service/AdminService';
import { getApiPath } from '../../../Utils/PathUtils';
import AddDashboard from './AddDashboard';
import { createDashboard, deleteDashboardById, editDashboard, getDashboards } from './DashboardService';

const URL = getApiPath(DASHBOARDS, Routes.METADATA);

const ActionCell = ({ row }) => {
  const { setDeleteDashboard, setIsEdit, setEditRow } = row;

  const onEdit = () => {
    setIsEdit(true);
    setEditRow(row);
  };

  const onDelete = () => {
    setDeleteDashboard(true);
    setEditRow(row);
  }

  const menuItems = [
    {
      text: 'Edit',
      onClick: () => onEdit(),
    },
    {
      text: 'Delete',
      onClick: () => onDelete(),
    },
  ];

  return (
    <Tooltip title='Actions' disableFocusListener>
      <IconMenuButton id='actions' menuItems={menuItems} size='small'>
        <EllipsisVerticalIcon />
      </IconMenuButton>
    </Tooltip>
  );
};

const columns = [
  {
    header: 'ID',
    accessor: 'id',
    sortFunction: compareNumbers,
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('id'),
  },
  {
    header: 'Name',
    accessor: 'name',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('name'),
    filterComponent: TextFieldFilter,
  },
  {
    header: 'Description',
    accessor: 'description',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('description'),
    filterComponent: TextFieldFilter,
  },
  {
    header: 'Entity Id',
    accessor: 'entityId',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('entityId'),
    filterComponent: TextFieldFilter,
  },
  {
    header: 'Entity Name',
    accessor: 'entityName',
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter('entityName'),
    filterComponent: TextFieldFilter,
  },
  {
    accessor: 'action',
    customCell: ActionCell,
    align: 'right',
  },
];
const DashboardManagement = () => {
  const [rows, setRows] = useState<any>([]);
  const [rerender, setRerender] = useState(false);
  const [deleteDashboard, setDeleteDashboard] = useState(false);
  const [isNewDashboard, setIsNewDashboard] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newRow, setNewRow] = useState({
    name: '',
    description: '',
    tenantBiClientId: '',
    biClientName: '',
    config: {
      entityId: '',
      entityName: '',
      entityType: '',
      paginatedReportIds: ''
    }
  })
  const [editRow, setEditRow] = useState({ id: '', ...newRow });
  const [tenantBiClients, setTenantBiClients] = useState([{
    id: '1',
    biClientName: ''
  }])

  const dispatch = useDispatch();
  const handleNewDashboard = () => {
    setIsNewDashboard(false)
    setNewRow({
      ...newRow, name: '',
      description: '',
      config: {
        entityId: '',
        entityName: '',
        entityType: '',
        paginatedReportIds: ''
      }
    })
  }


  useEffect(() => {
    (async () => {
      const res = await getDashboards(`${URL}`);
      if (!res?.err) {
        setRows(res);
      }
      else {
        dispatch(
          showBanner({
            variant: 'error',
            message: res.message,
          })
        );
      }
      setRerender(false);
    })();
  }, [isEdit, rerender]);

  useEffect(() => {
    (async () => {
      const TENANT_BI_CLIENTS_URL = getApiPath(GET_TENANT_BI_CLIENTS, Routes.METADATA)
      const tenantBiClientsResponse = await getData(TENANT_BI_CLIENTS_URL)
      if (!tenantBiClientsResponse.error) {
        setTenantBiClients(tenantBiClientsResponse)
        setNewRow({ ...newRow, tenantBiClientId: (tenantBiClientsResponse.find((tenantBiClient) => tenantBiClient.biClientName = POWER_BI)).id, biClientName: (tenantBiClientsResponse.find((tenantBiClient) => tenantBiClient.biClientName = POWER_BI)).biClientName })
      } else {
        dispatch(
          showBanner({
            variant: 'error',
            message: tenantBiClientsResponse.message,
          })
        );
      }
    })();
  }, []);

  const CustomButtonHeader = (props) => {
    return (
      <div>
        <Button
          size='small'
          variant='secondary'
          icon={Filter}
          style={{ marginRight: 15 }}
          onClick={props.toggleFilters}
          data-testid='filter-button'>
          Filter
        </Button>
        <Button size='small' variant='primary' onClick={() => setIsNewDashboard(true)}>
          Add new Dashboard
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Table
        title='Dashboards'
        subtitle='Manage your dashboards'
        columns={columns}
        rows={rows.map((row) => ({
          ...row,
          entityId: row.config.entityId,
          entityName: row.config.entityName,
          entityType: row.config.entityType,
          paginatedReportIds: row.config.paginatedReportIds,
          biClientName: (tenantBiClients.find((tenantBiClient) => tenantBiClient.id = row.tenantBiClientId))?.biClientName,
          setDeleteDashboard,
          setIsEdit,
          setEditRow,
        }))}
        rowId='id'
        initialSortedColumn='id'
        initialSortOrder='asc'
        rowsPerPageOptions={[5, 10, 50, 'All']}
        rowProps={{ hover: false }}
        tablePaginationProps={{
          labelDisplayedRows: ({ from, to, count }) =>
            `${count === 1 ? 'Dashboard' : 'Dashboards'} ${from}-${to} of ${count}`,
          truncate: true,
        }}
        CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
      />
      {deleteDashboard && (
        <CustomModal
          display={true}
          handleClose={() => setDeleteDashboard(false)}
          title='Do you want to delete dashboard?'
          type='error'
          variant='error'
          buttonProps={[
            { label: CANCEL_LABEL, onClick: () => setDeleteDashboard(false) },
            {
              label: 'Delete', onClick: () => {
                deleteDashboardById(`${URL}/${editRow.id}`);
                setDeleteDashboard(false);
                setRerender(true);
                dispatch(showBanner({
                  variant: 'success',
                  message: `Successfully deleted ${editRow.name} dashboard`
                }))
              }
            },
          ]}
        />
      )}
      {isNewDashboard && (
        <CustomModal
          title='New Dashboard'
          display={isNewDashboard}
          handleClose={handleNewDashboard}
          buttonProps={[
            {
              label: 'Cancel', onClick: handleNewDashboard
            },
            {
              label: 'Save', onClick: () => {
                const checkName = rows.find(row => row.name === newRow.name)
                if (!checkName) {
                  createDashboard(URL, newRow);
                  setRerender(true);
                  handleNewDashboard()
                  dispatch(showBanner({
                    variant: 'success',
                    message: `Successfully added ${newRow.name} dashboard`
                  }))
                } else {
                  dispatch(
                    showBanner({
                      variant: 'error',
                      message: ` ${newRow.name} dashboard already exists. Please try adding dashboard with unque name.`,
                    })
                  )
                  handleNewDashboard()
                }
              }
            },
          ]}
          body={<AddDashboard rowData={newRow} tenantBiClientsData={tenantBiClients} />}
        />
      )}
      {isEdit && (
        <CustomModal
          title='Edit Dashboard'
          display={isEdit}
          handleClose={() => setIsEdit(false)}
          buttonProps={[
            { label: 'Cancel', onClick: () => setIsEdit(false) },
            {
              label: 'Save', onClick: () => {
                editDashboard(`${URL}/${editRow.id}`, editRow);
                setRerender(true);
                setIsEdit(false);
                dispatch(showBanner({
                  variant: 'success',
                  message: `Successfully updated ${editRow.name} dashboard`
                }))
              }
            },
          ]}
          body={<AddDashboard rowData={editRow} tenantBiClientsData={tenantBiClients} />}
        />
      )}
    </React.Fragment>
  );
};

export default DashboardManagement;

import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import Cookies from 'js-cookie';
import { API_KEY, TENANT, USER_ID } from '../Constants';

const TENANT_ID: string | any = process.env.REACT_APP_TENANT_ID;
const CLIENT_ID: string | any = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URL: string | any = process.env.REACT_APP_REDIRECT_URL;

export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: 'https://login.microsoftonline.com/' + TENANT_ID,
    redirectUri: REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containerPII) => {
        console.log(message);
      },
      logLevel: LogLevel.Error,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const setActiveMsalAccount = (event: EventMessage) => {
  if (msalInstance?.getAllAccounts().length > 0 && event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance?.setActiveAccount(account);
  }
};

export const PowerBiPermissionScopes: string[] = [
  'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
  'https://analysis.windows.net/powerbi/api/Report.ReadWrite.All',
  'https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All',
  'https://analysis.windows.net/powerbi/api/Content.Create',
];

export const isAuthorised = (auth?: boolean) => {
  if (auth) {
    const value = Cookies.get(USER_ID) && Cookies.get(API_KEY);
    return value ? true : false;
  } else {
    return true;
  }
};

export const getTenant = () => {
  if (Cookies.get(TENANT)) {
    return Cookies.get(TENANT)?.toLowerCase();
  } else {
    return '';
  }
};

export const getAccessToken = async (): Promise<string> => {
  let accessToken = '';
  const account = msalInstance?.getActiveAccount();
  if (account) {
    const accessTokenRequest = {
      scopes: PowerBiPermissionScopes,
      account: account,
    };
    await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        accessToken = response.accessToken;
      })
      .catch((error) => {
        console.log('Error while acquireTokenSilent', error);
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance
            .acquireTokenPopup(accessTokenRequest)
            .then((response) => {
              accessToken = response.accessToken;
            })
            .catch((errorRes) => {
              console.log(errorRes);
            });
        }
      });
  }
  return accessToken;
};

export const authenticatePBIUser = (
  setIsAuthFailure: React.Dispatch<React.SetStateAction<boolean>>,
  email?: string
) => {
  loginUser('silent', email)
    .then((response) => {
      msalInstance.setActiveAccount(response.account);
    })
    .catch((error) => {
      console.log('Error while authenticating PBI user', error);
      if (error instanceof InteractionRequiredAuthError) {
        loginUser('popup', email)
          .then((response) => {
            msalInstance.setActiveAccount(response.account);
          })
          .catch((errorRes) => {
            setIsAuthFailure(true);
            console.log(errorRes);
          });
      } else {
        loginUser('popupSilent', email)
          .then((response) => {
            msalInstance.setActiveAccount(response.account);
          })
          .catch((errorRes) => {
            setIsAuthFailure(true);
            console.log(errorRes);
          });
      }
    });
};

export const loginUser = (loginType: string, email?: string) => {
  let loginRequest: any = {
    scopes: PowerBiPermissionScopes,
    loginHint: email !== undefined ? email : Cookies.get('user.email'),
  };
  if (loginType === 'popup') {
    return msalInstance.loginPopup(loginRequest);
  } else if (loginType === 'popupSilent') {
    loginRequest['prompt'] = 'none';
    return msalInstance.loginPopup(loginRequest);
  } else {
    return msalInstance.ssoSilent(loginRequest);
  }
};

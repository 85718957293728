import Divider from 'apollo-react/components/Divider';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useAppStyles } from "../../AppStyles";
import { InfoMessage } from '../../components//InfoMessage/InfoMessage';
import { DashboardCard } from '../../components/DashboardCard/DashboardCard';
import { DashboardGrid } from '../../components/DashboardCard/DashboardGrid';
import { SelfServiceGrid } from '../../components/DashboardCard/SelfServiceGrid';
import { HomeFooter } from "../../components/Footer/Footer";
import { DISABLE_STUDIES_LENGTH_ARR, LIBRARY_OF_VISUALIZATION, SELF_SERVICE_REPORT, SINGLE_STUDY_INFO_MESSAGE } from '../../Constants';
import { SelectedStudiesSlice } from '../../Redux/Slice/SelectedStudiesSlice';
import { RootState } from '../../Redux/store';
import { isStrTrue } from '../../Utils';
import { HomeHeader } from './HomeHeader';
import { HomeContext } from "./HomeLayout";

export const Home = () => {
  const appClasses = useAppStyles();
  const HomeContextData: any = useContext(HomeContext)
  const { configData } = HomeContextData;
  const dispatch = useDispatch()
  const selectedStudies = useSelector((state: RootState) => {
    let selStudies = state.selectedStudiesSlice.selectedStudies
    if (isStrTrue(process.env.REACT_APP_CONV_AI_CUSTOM_STUDY_ENABLED) && selStudies.includes('pds262')) {
      selStudies = selStudies.filter(s => s !== 'pds262');
      dispatch(SelectedStudiesSlice.actions.setStudies(selStudies.filter(s => s !== 'pds262')))
    }
    return selStudies
  });
  const handleDisable = (name: string) => !DISABLE_STUDIES_LENGTH_ARR.includes(selectedStudies.length) && name === LIBRARY_OF_VISUALIZATION;

  return (
    <React.Fragment>
      <div className={appClasses.pageContainer}>
        <HomeHeader />
        <div className={selectedStudies.length !== 0 ? appClasses.divContainer : appClasses.disabledDiv}>
          {configData?.menuItems?.map((menuItem: any) => {
            if (menuItem.name === SELF_SERVICE_REPORT) {
              return (
                <div className={appClasses.divContainer} key={JSON.stringify(menuItem)}>
                  <SelfServiceGrid child={menuItem} />
                  <Divider />
                </div>)
            }
            else {
              return (
                <div className={appClasses.divContainer} key={JSON.stringify(menuItem)}>
                  <Typography variant="h2">
                    {menuItem.name}
                  </Typography>
                  <InfoMessage message={SINGLE_STUDY_INFO_MESSAGE} disable={handleDisable(menuItem.name)} />
                  {menuItem.description && <Typography>{menuItem.description}</Typography>}
                  {menuItem.children.filter((child: any) => child.children).map((child: any) => <DashboardGrid child={child} key={JSON.stringify(child)} />)}
                  <Grid container>
                    {menuItem.children.filter((child: any) => child.pbiEntityName).map((item: any) => <DashboardCard item={item} key={JSON.stringify(item)} />)}
                  </Grid>
                  <Divider />
                </div>)
            }
          })}
        </div>
        <HomeFooter />
      </div>
    </React.Fragment >
  );
};
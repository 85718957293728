import { useEffect, useRef } from 'react';

export const ScrollToBottom = ({ dependencyList, delay = 0 }) => {

    const elRef = useRef<any>(null);
    const scrollToView = (elRef) => elRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    useEffect(() => {
        if (delay === 0) {
            scrollToView(elRef);
        } else {
            setTimeout(() => scrollToView(elRef), delay);
        }
    }, dependencyList);

    return <div ref={elRef} />;
}

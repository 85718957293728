import { AdminLayout } from './AdminLayout';
import { AdminPage } from './AdminPage';

const adminRoutes = [
  {
    path: '/admin',
    component: AdminPage,
    layout: AdminLayout,
    exact: true,
    authentication: true,
  },
];

export default adminRoutes;

import { createSlice } from '@reduxjs/toolkit';

export const SelectedStudiesSlice = createSlice({
    name: 'selectedStudiesSlice',
    initialState: {
        selectedStudies: [] as Array<any>,
        wasSet: false
    },
    reducers: {
        setStudies(state, { payload }) {
            state.selectedStudies = payload;
            setSelStudiesInSession(state.selectedStudies);
        },
        addStudy(state, { payload }) {
            state.selectedStudies.push(payload);
            setSelStudiesInSession(state.selectedStudies);
        },
        removeStudy(state, { payload }) {
            state.selectedStudies = state.selectedStudies.filter((study) => study !== payload);
            setSelStudiesInSession(state.selectedStudies);
        },
        setWasSet(state, { payload }) {
            state.wasSet = true
        }
    }
});

const setSelStudiesInSession = (selStudies) => {
    window.sessionStorage.setItem('sel-studies', selStudies.join(','));
}

export const selectedStudiesReducer = SelectedStudiesSlice.reducer;
export const selectedStudiesAction = SelectedStudiesSlice.actions;
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Divider from 'apollo-react/components/Divider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useAppStyles } from '../../AppStyles';
import { GO_BUTTON_LABEL } from '../../Constants';
import { AuditAction, AuditCategory, AuditSubCategory } from '../../Constants/Enums';
import { SelectedReportSlice } from "../../Redux/Slice/SelectedReportSlice";
import { SelectedStudiesSlice } from '../../Redux/Slice/SelectedStudiesSlice';
import { UserStudiesApi } from '../../Redux/apis/UserStudiesApi';
import { RootState } from "../../Redux/store";
import logUserAction from '../../Service/AuditLogService';
import { isStrTrue } from '../../Utils';
import { AnalyticMenu } from '../../components/AnalyticMenu/AnalyticMenu';
import { StudiesAutoCompleteDropdown } from "../../components/studies/StudiesAutoCompleteDropdown";

const useStyles = makeStyles({
    buttonContainer: {
        marginLeft: '90px',
        gap: '4px',
        width: '53px',
        height: '40px'
    }
});

export const AnalyzeHeader = () => {
    const appClasses = useAppStyles();
    const classes = useStyles();
    const { data: studiesApiData } = UserStudiesApi.useFetchUserStudiesQuery({});
    const selectedReport = useSelector((state: RootState) => state.selectedReportSlice.selectedReport);
    const dispatch = useDispatch();
    const selectedStudies = useSelector((state: RootState) => {
        let selStudies = state.selectedStudiesSlice.selectedStudies;
        if (isStrTrue(process.env.REACT_APP_CONV_AI_CUSTOM_STUDY_ENABLED) && selStudies.includes('pds262')) {
            selStudies = selStudies.filter(s => s !== 'pds262');
            dispatch(SelectedStudiesSlice.actions.setStudies(selStudies.filter(s => s !== 'pds262')))
        }
        if (!selStudies.length && studiesApiData) {
            dispatch(SelectedStudiesSlice.actions.addStudy(studiesApiData[0]))
            dispatch(SelectedStudiesSlice.actions.setWasSet(true))
        }
        return selStudies
    });
    const [report, setReport] = React.useState(selectedReport);
    const [studies, setStudies] = React.useState(selectedStudies);

    const handleGoButtonChange = () => {
        dispatch(SelectedReportSlice.actions.setSelectedReport(report));
        dispatch(SelectedStudiesSlice.actions.setStudies(studies));
        logUserAction(AuditCategory.REPORT, AuditSubCategory.VIEW_REPORT, AuditAction.VIEW,
            { reportId: report?.reportId, workspaceId: report?.workspaceId, reportName: report?.name }
        );
    }

    useEffect(() => {
        setReport(selectedReport);
    }, [selectedReport]);

    return (
        <div className={appClasses.divContainer}>
            <div className={appClasses.divRow}>
                <StudiesAutoCompleteDropdown interactive width={'45%'} studies={studies} setStudies={setStudies} report={report} />
                <AnalyticMenu selectedReport={selectedReport} report={report} setReport={setReport} studiesLength={studies.length} />
                <Button className={classes.buttonContainer} variant="secondary" size="small" onClick={handleGoButtonChange} disabled={studies.length === 0}>
                    {GO_BUTTON_LABEL}
                </Button>
            </div>
            <Divider style={{ borderBottomWidth: '2px', borderColor: 'black' }} />
        </div>
    )
}
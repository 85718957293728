const eventTypes = [
  'keypress',
  'mousemove',
  'mousedown',
  'scroll',
  'touchmove',
  'pointermove',
];

export const gotoCore = () => {
  window.location.href = process.env.REACT_APP_CORE_URL;
};

export const uuidv4 = () => {
  if ('randomUUID' in window.crypto) return window.crypto.randomUUID();

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (window?.crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    )?.toString(16)
  );
};

export const addEventListeners = (listener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};

export const removeEventListeners = (listener) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
    });
  }
};

export const isStrTrue = (str) => str?.toLowerCase?.() === 'true';
